import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export const ImageModal = ({
  isOpen,
  imageSrc,
  onClose,
}: {
  imageSrc: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  const defaultImage = "/default-image.jpg";
  const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    target.src = defaultImage;
  };

  return (
    <Dialog
      open={!!isOpen}
      as="div"
      className="relative z-50"
      onClose={onClose}
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className="font-medium text-gray-900 text-right"
            >
              <button onClick={onClose}>
                <XMarkIcon className="h-5 fill-black" />
              </button>
            </Dialog.Title>
            <img
              className={classNames(
                loadingImage ? "hidden" : "w-full h-auto object-contain"
              )}
              src={imageSrc}
              alt="detalle de imagen"
              onLoad={() => setLoadingImage(false)}
              onError={handleImageError}
            />
            {loadingImage && (
              <div className="py-2 w-full h-full text-center flex flex-col justify-center text-gray-medium">
                <span>{t("Content.loading-image")}</span>
              </div>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
