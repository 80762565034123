import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const usePagination = (
  items: Record<any, any>[],
  totalCount: number,
  pageSize: number,
  currentPage: number
) => {
  const { t } = useTranslation();
  const paginationRange = useMemo(() => {
    const totalPages = Math.floor(totalCount / pageSize);
    const pageStart = currentPage > 0 ? currentPage * pageSize : 1;
    const pageEnd = (currentPage + 1) * pageSize;
    const paginatedItems =
      totalPages <= 0
        ? items
        : items.slice(pageStart, totalCount > pageEnd ? pageEnd : totalCount);

    return {
      items: paginatedItems,
      totalPages,
      description:
        totalPages <= 0
          ? `${t("Pagination.showing-page")} 1`
          : `${t("Pagination.showing-page")} ${currentPage} ${t(
              "Pagination.of"
            )} ${totalPages}`,
    };
  }, [items, totalCount, pageSize, currentPage]);

  return paginationRange;
};
