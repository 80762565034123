import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import {
  CheckVideoResponse,
  ConversationResponse,
  MessagesResponse,
  SendMessageResponse,
  TokenResponse,
  VideoTokenRoomResponse,
} from "../interfaces/apiResponses";
import { ChatMessage, ConversationInfo } from "../interfaces/chat";
import { CustomError } from "../utils/CustomError";
import { apiFetch } from "./api";

export async function getToken(): Promise<string> {
  try {
    const data = await apiFetch<TokenResponse>(`/chat/token`, "POST");
    return data.token.jwt;
  } catch (error) {
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function getConversationInfo(
  courseId: string
): Promise<ConversationInfo> {
  const data = await apiFetch<ConversationResponse>(
    `/chat/conversation/student?courseId=${courseId}`,
    "POST"
  );
  return data.conversation;
}

export async function getMessages(
  conversationSid: string
): Promise<ChatMessage[]> {
  const data = await apiFetch<MessagesResponse>(
    `/chat/message?conversationSid=${conversationSid}`,
    "GET"
  );
  return data.msgs.reverse();
}

export async function sendMessage(
  cursoId: number,
  message: string,
  conversationSid?: string,
  replyTo?: ChatMessage
): Promise<ChatMessage> {
  const params = new URLSearchParams({
    messageBody: message,
    msgType: "normal",
    courseId: cursoId.toString(),
  });

  if (replyTo) {
    params.set("parentMsgSid", replyTo.sid);
    params.set("msgType", "reply");
  }
  if (conversationSid) {
    params.set("conversationSid", conversationSid);
  }

  const data = await apiFetch<SendMessageResponse>(
    `/chat/message?${params.toString()}`,
    "POST"
  );
  return data.msg;
}

export async function sendQuestion(
  questionId: string,
  courseId: string,
  conversationSid?: string | null
): Promise<SendMessageResponse> {
  return await apiFetch<SendMessageResponse>(
    `/chat/message/question?questionId=${questionId}&courseId=${courseId}&conversationSid=${conversationSid}`,
    "POST"
  );
}

export async function checkVideoCall(): Promise<boolean> {
  const data = await apiFetch<CheckVideoResponse>(
    "/chat/conversation/checkVideo",
    "GET"
  );

  return data.hasVideo;
}

export async function getVideoToken(conversationSid: string): Promise<string> {
  const data = await apiFetch<VideoTokenRoomResponse>(
    `/chat/tokenRoom?roomName=${conversationSid}`,
    "POST"
  );

  return data.token.jwt;
}
