import { useTranslation } from "react-i18next";
import Loading from "../components/Layout/Loading";
import { Meeting } from "../components/Meeting/Meeting";
import { useClassroom } from "../hooks/useClassroom";
import { useCourses } from "../hooks/useCourses";

export function LessonVideo() {
  const { t } = useTranslation();
  const { isLoadingCourses, selectedCourse } = useCourses();
  const { isLoadingLesson, selectedLesson } = useClassroom();

  if (
    (isLoadingCourses && !selectedCourse) ||
    (isLoadingLesson && !selectedLesson)
  ) {
    return (
      <div className="fixed left-0 right-0 top-24 bottom-0">
        <Loading text={t("Meeting.loading-meeting")} />
      </div>
    );
  }

  // Disabled Meeting component embed, it now work with KeepMeeting from App.tsx for to use in all application.
  // return <Meeting />;
  return <></>;
}
