import { useEffect, useRef, useState } from "react";
import Video, {
  AudioTrack,
  AudioTrackPublication,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Track,
  VideoTrack,
  VideoTrackPublication,
} from "twilio-video";

export function Participant({
  participant,
}: {
  participant: Video.Participant;
}) {
  const videoRef: any = useRef();
  const audioRef: any = useRef();
  const [videoTracks, setVideoTracks] = useState<
    (LocalVideoTrack | RemoteVideoTrack | null)[]
  >([]);
  const [audioTracks, setAudioTracks] = useState<
    (LocalAudioTrack | RemoteAudioTrack | null)[]
  >([]);

  const trackPubsToTracks = (
    trackMap: Map<Track.SID, VideoTrackPublication | AudioTrackPublication>
  ): (
    | LocalVideoTrack
    | RemoteVideoTrack
    | LocalAudioTrack
    | RemoteAudioTrack
    | null
  )[] => {
    return Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  };

  useEffect(() => {
    setVideoTracks(
      trackPubsToTracks(participant.videoTracks) as (
        | LocalVideoTrack
        | RemoteVideoTrack
        | null
      )[]
    );
    setAudioTracks(
      trackPubsToTracks(participant.audioTracks) as (
        | LocalAudioTrack
        | RemoteAudioTrack
        | null
      )[]
    );

    const trackSubscribed = (
      track:
        | LocalVideoTrack
        | RemoteVideoTrack
        | LocalAudioTrack
        | RemoteAudioTrack
    ) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="w-full">
      <video ref={videoRef} autoPlay={true} className="object-cover w-full" />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
}
