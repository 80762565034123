import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useUser } from "../../../hooks/useUser";
import { Meeting as MeetingI } from "../../../interfaces/meeting";
import { meetingState } from "../../../state/meeting";
import { JoinScreen } from "./JoinScreen";
import { MeetingView } from "./MeetingView";
const {
  MeetingProvider,
  createMicrophoneAudioTrack,
  createCameraVideoTrack,
} = require("@videosdk.live/react-sdk");

enum MeetingViewStatus {
  "joinScreen",
  "videoScreen",
}

interface OnlineMeetingProps {
  modeComplete?: boolean;
}

export const OnlineMeeting = ({ modeComplete = true }: OnlineMeetingProps) => {
  const { user, logout } = useUser();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const [viewStatus, setViewStatus] = useState<MeetingViewStatus>(
    MeetingViewStatus.joinScreen
  );

  const onJoinAction = async (newMeeting: MeetingI) => {
    setMeeting({ ...meeting, ...newMeeting });
    setViewStatus(MeetingViewStatus.videoScreen);
  };

  const getAudioTrack = async () => {
    const audioTrack = await createMicrophoneAudioTrack({
      encoderConfig: "speech_standard",
      noiseConfig: {
        noiseSuppression: true,
        echoCancellation: true,
        autoGainControl: true,
      },
    });
    setCustomAudioTrack(audioTrack);
  };
  let [customAudioTrack, setCustomAudioTrack] = useState();

  const getVideoTrack = async () => {
    const videoTrack = await createCameraVideoTrack({
      encoderConfig: "h240p_w320p",
      optimizationMode: "motion",
      multiStream: true,
    });
    setCustomVideoTrack(videoTrack);
  };
  let [customVideoTrack, setCustomVideoTrack] = useState();

  useEffect(() => {
    if (user?.username === undefined) {
      logout();
    }
    getAudioTrack();
    getVideoTrack();
  }, []);

  let classNameModeComplete = modeComplete
    ? "fixed left-0 right-0 top-12 bottom-0"
    : "";

  return (
    <div className={classNameModeComplete}>
      {modeComplete && viewStatus === MeetingViewStatus.joinScreen ? (
        <JoinScreen onJoinAction={onJoinAction} />
      ) : (
        viewStatus === MeetingViewStatus.videoScreen &&
        meeting &&
        meeting.SDKToken &&
        user && (
          <MeetingProvider
            config={{
              maxResolution: "hd",
              name: user.name,
              participantId: user.username,
              meetingId: meeting.meetingId ?? "",
              micEnabled: meeting.micEnabled ?? false,
              webcamEnabled: meeting.webcamEnabled ?? false,
              customMicrophoneAudioTrack: customAudioTrack,
              customCameraVideoTrack: customVideoTrack,
            }}
            token={meeting.SDKToken}
            joinWithoutUserInteraction
          >
            <MeetingView modeComplete={modeComplete} />
          </MeetingProvider>
        )
      )}
    </div>
  );
};
