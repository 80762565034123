import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { meetingState } from "../../../state/meeting";
import { getRoutePath } from "../../../utils/portalPages";
import Loading from "../../Layout/Loading";
import { VideoWrapper } from "./VideoWrapper";
import { selectedSessionState } from "../../../state/global";
import { useCourses } from "../../../hooks/useCourses";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSDKMeeting } from "../../../context/SdkMeetingContext";

const { useMeeting } = require("@videosdk.live/react-sdk");

interface MeetingViewProps {
  modeComplete?: boolean;
}

export const MeetingView = ({ modeComplete = true }: MeetingViewProps) => {
  const { t } = useTranslation();
  const [loadingText, setLoadingText] = useState<string>(t("MeetingView.loading-meeting"));
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isTeacherPresent, setIsTeacherPresent] = useState(false);
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const [selectedLesson, setSelectedLesson] = useRecoilState(selectedSessionState);
  const { selectedCourse } = useCourses();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sdkMeeting, setSDKMeeting } = useSDKMeeting();

  const SDKMeeting = useMeeting({
    onError: (e: any) => {
      // eslint-disable-next-line no-console
      console.log(e);
      if (sdkMeeting) {
        sdkMeeting.leaveMeeting();
      }
      setErrorMsg(`sdk video error`);
    },
    onMeetingStateChanged: (e: any) => {
      if (e.state === "CLOSED") {
        setMeeting(undefined);
        setSelectedLesson(undefined);
        if (modeComplete) {
          const courseId = selectedCourse?.id.toString();
          navigate(getRoutePath("classroom", { courseId: `${courseId}` }));
        }
      }
    },
  });

  /**
   * @description detect if the teacher have joined the meeting
   */

  useEffect(() => {
    setSDKMeeting(SDKMeeting);
  }, [SDKMeeting, setSDKMeeting]);

  useEffect(() => {
    if (meeting?.session?.teacherId && sdkMeeting?.participants) {
      let teacher = sdkMeeting.participants.get(meeting?.session.teacherId);
      setIsTeacherPresent(teacher !== undefined);
    } else if (sdkMeeting) {
      sdkMeeting.leave();
      setMeeting(undefined);
      setSelectedLesson(undefined);
      const courseId = selectedCourse?.id.toString();
      navigate(getRoutePath("classroom", { courseId: `${courseId}` }));
    }
  }, [meeting, sdkMeeting]);

  /**
   * @description since VideoSDK does not have an event to notify when the meeting is ended
   * we need to add this extra logic to detect the status of the meeting
   * when the user is joining or waiting for the teacher and the when the
   * meeting is ended.
   */
  useEffect(() => {
    if (!meeting) setLoadingText("Cargando clase...");
    if (!isTeacherPresent) setLoadingText("Espera mientras el profesor inicializa la clase...");
    if (meeting && isTeacherPresent) setLoadingText("");
  }, [isTeacherPresent, meeting]);

  if (errorMsg.length) {
    return (
      <div className="h-full flex flex-col justify-center text-center">
        <h1 className="text-gray-torcal text-xl mb-4">{t("MeetingView.error")}</h1>
        <Link className="text-green-torcal" to={getRoutePath("courses")}>
          {t("MeetingView.back-to-courses")}
        </Link>

        <p className="mt-5 p-3 text-red-500 text-xs text-center">{errorMsg}</p>
      </div>
    );
  }

  return loadingText.length > 0 ? <Loading text={loadingText} /> : <VideoWrapper modeComplete={modeComplete} />;
};
