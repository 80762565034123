import { Client, Message } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";
import {
  checkVideoCall,
  getConversationInfo,
  getMessages,
  getToken,
  getVideoToken,
  sendMessage as sendMessageAPI,
  sendQuestion as sendQuestionAPI,
} from "../api/chat";
import {
  ChatMessage,
  ChatMessageAttributes,
  ConversationInfo,
} from "../interfaces/chat";
import { Course } from "../interfaces/courses";
import { useCourses } from "./useCourses";

const getChatMessageFromTwilio = (msg: Message): ChatMessage => {
  const msgAttributes = msg.attributes as unknown as ChatMessageAttributes;
  return {
    sid: msg.sid,
    author: msg.author ?? "Torcal",
    body: msg.body ?? "",
    dateCreated: msg.dateCreated?.toString() ?? "",
    attributes: {
      idUser: msgAttributes.idUser ?? "Torcal",
      usernameForDisplay: msgAttributes.usernameForDisplay ?? "Torcal",
      parentMsgSid: msgAttributes.parentMsgSid ?? "",
      msgType: msgAttributes.msgType,
      image: msgAttributes.image,
      answers: msgAttributes.answers,
      indexRightAnswer: msgAttributes.indexRightAnswer,
    },
  };
};

interface VideoConfig {
  hasVideo: boolean;
  token?: string;
  roomName?: string;
}

export function useChat(canInitialize: boolean = false) {
  const { selectedCourse } = useCourses();
  const [userToken, setUserToken] = useState<string>();
  const [videoConfig, setVideoConfig] = useState<VideoConfig>({
    hasVideo: false,
  });
  const [conversationInfo, setConversationInfo] = useState<ConversationInfo>();
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [userMessages, setUserMessages] = useState<ChatMessage[]>([]);
  let client: Client | null = null;

  const getUserMessages = async (sid: string) => {
    setUserMessages((await getMessages(sid)) ?? []);
    setIsLoadingMessages(false);
  };

  const getUserMessagesFromTwilio = (msg: Message) => {
    setUserMessages((previous) => {
      return [...(previous ?? []), getChatMessageFromTwilio(msg)];
    });
  };

  const initialize = useCallback(async () => {
    if (!selectedCourse?.id) return;
    setUserToken(undefined);
    setConversationInfo(undefined);
    Promise.all([
      getToken(),
      getConversationInfo(selectedCourse.id.toString()),
    ]).then((values) => {
      setUserToken(values[0]);
      if (values[1] !== null) {
        setConversationInfo(values[1]);
        getUserMessages(values[1].sid);
      } else {
        setConversationInfo(undefined);
        setIsLoadingMessages(false);
      }

      client = new Client(values[0]);
      client.on("messageAdded", getUserMessagesFromTwilio);
    });
  }, [selectedCourse?.id]);

  useEffect(() => {
    if (!canInitialize) return;
    if (!conversationInfo?.sid) return;

    const interval = setInterval(async () => {
      const hasVideo = await checkVideoCall();
      if (hasVideo !== videoConfig.hasVideo) {
        const newConfig: VideoConfig = { hasVideo };
        if (hasVideo && conversationInfo?.sid) {
          newConfig.roomName = conversationInfo.sid;
          newConfig.token = await getVideoToken(conversationInfo.sid);
        }
        setVideoConfig(newConfig);
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [videoConfig, conversationInfo?.sid]);

  useEffect(() => {
    if (!selectedCourse?.id) return;
    initialize();

    if (!canInitialize) return;

    return () => {
      if (client) {
        client.off("messageAdded", getUserMessagesFromTwilio);
        client = null;
      }
    };
  }, [selectedCourse?.id]);

  const sendMessage = async (message: string, replyTo?: ChatMessage) => {
    //if (!conversationInfo?.sid) return;

    if (selectedCourse !== undefined) {
      await sendMessageAPI(
        selectedCourse.id,
        message,
        conversationInfo?.sid,
        replyTo
      );
    }
  };

  const sendQuestion = async (questionId: string) => {
    if (selectedCourse !== undefined) {
      await sendQuestionAPI(
        questionId,
        selectedCourse.id.toString(),
        conversationInfo?.sid
      );
    }
  };

  const getMessage = (sid?: string): ChatMessage | undefined => {
    //if (!sid) return;
    return userMessages?.find((m) => m.sid === sid);
  };

  return {
    videoConfig,
    userMessages,
    isLoadingMessages,
    sendMessage,
    sendQuestion,
    getMessage,
    isAvailableChat: !!(
      userToken &&
      //conversationInfo &&
      selectedCourse &&
      selectedCourse.hasChat
    ),
  };
}
