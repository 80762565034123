import { Route, Routes, useNavigate, useLocation, matchPath } from "react-router-dom";
import Chat from "./components/Chat/Chat";
import Loading from "./components/Layout/Loading";
import PageWrapper from "./components/Layout/PageWrapper";
import Spinner from "./components/Layout/Spinner";
import Modal from "./components/Modal/Modal";
import { useMaintenance } from "./hooks/useMaintenance";
import { useUser } from "./hooks/useUser";
import { routesPath } from "./interfaces/portalPages";
import { Login } from "./pages/Login";
import { getProtectedPages, getPublicPages, getRoutePath } from "./utils/portalPages";
import { KeepMeeting } from "./components/Meeting/KeepMeeting";

function App() {
  const { isLoading, error, isAuthenticated } = useUser();
  const { isInMaintenance } = useMaintenance();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMeetingPage = !!matchPath(routesPath["lesson-meeting"], pathname);

  if (error) {
    navigate(getRoutePath("login"));
  }

  return (
    <>
      {isAuthenticated ? <KeepMeeting /> : null}
      <Modal isOpen={isInMaintenance} title={"Estamos actualizando el servidor."} closeModal={() => {}} showCloseIcon={false}>
        <div className="my-10 flex justify-center">
          <Spinner className="h-20 w-20" lineColor="fill-red-500" />
        </div>
      </Modal>
      {!isInMaintenance && isLoading && (
        <div className="flex flex-col h-screen justify-center align-middle">
          <Loading />
        </div>
      )}
      {!isInMaintenance && !isLoading && (
        <Routes>
          {getPublicPages().map((page) => (
            <Route key={page.path} path={page.path} element={page.pageComponent} />
          ))}
          {getProtectedPages().map((page) => (
            <Route key={page.path} path={page.path} element={<PageWrapper>{page.pageComponent}</PageWrapper>} />
          ))}
          <Route path="*" element={<Login />} />
        </Routes>
      )}
      {isAuthenticated && !isMeetingPage ? <Chat /> : null}
    </>
  );
}

export default App;
