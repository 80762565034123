import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingBar from "../components/Layout/LoadingBar";
import { ToolBar } from "../components/Layout/ToolBar";
import { AvailableTestBar } from "../components/Test/AvailableTestBar";
import { ExistentTestTable } from "../components/Test/ExistentTestTable";
import { FinishTestModal } from "../components/Test/FinishTestModal";
import { SubjectReviewWrapper } from "../components/TestBySubject/SubjectReviewWrapper";
import { SubTopicCards } from "../components/TestBySubject/SubTopicCards";
import { useTest } from "../hooks/useTest";
import { useTests } from "../hooks/useTests";
import { CreatedTest, TestBySubject } from "../interfaces/tests";
import { getRoutePath } from "../utils/portalPages";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useUser } from "../hooks/useUser";
interface ViewState {
  error?: string;
  showModal: boolean;
  isRegeneratingTest: boolean;
  isCreateExamTestLoading: boolean;
  isCreateErrorTestLoading: boolean;
  test?: CreatedTest;
  testType?: "exam" | "subject" | "review" | "failed";
  failedTestType?: "exam" | "subject" | "review";
}

export function TestBySubjectReview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserMetadata } = useUser();
  const [viewState, setViewState] = useState<ViewState>({
    showModal: false,
    isRegeneratingTest: false,
    isCreateExamTestLoading: false,
    isCreateErrorTestLoading: false,
  });
  const { courseId, subjectId, subtopicId } = useParams();
  const { isLoadingTestReview, testReview, isLoadingTestBySubject, testBySubject, errorTestReview, errorTestBySubject, refreshReview } = useTests();
  const { createTest, terminateTest } = useTest();
  const [subTopic, setSelectedSubTopic] = useState<TestBySubject>();

  useEffect(() => {
    refreshReview();
  }, []);

  const subject = useMemo(() => {
    return testBySubject?.find((t) => {
      if (t.id.toString() === subjectId) {
        if (subtopicId) {
          const subtopics = t.subtopics.find((st) => st.id.toString() === subtopicId);
          setSelectedSubTopic(subtopics);
        } else if (t.subtopics.length > 0) setSelectedSubTopic(t.subtopics[0]);
        return true;
      }
      return false;
    });
  }, [subjectId, testBySubject]);

  useEffect(() => {
    setUserMetadata({ currentPage: `Tests por temas: ${subject?.title}`, currentLesson: null, currentTest: null });
  }, [subject]);

  const navigateToTest = (testId: string): void => {
    if (!courseId) return;
    window.localStorage.setItem("test-dashboard", location.pathname);
    navigate(
      getRoutePath("tests.details", {
        courseId,
        testId,
      })
    );
  };

  const onCreateTest = async (testType: "exam" | "subject" | "review" | "failed", failedTestType?: "exam" | "subject" | "review") => {
    if (!courseId) return;

    try {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: !!(testType && !failedTestType),
        isCreateErrorTestLoading: !!(testType && failedTestType),
      });

      const newTest = await createTest(courseId, testType, failedTestType, subjectId, subtopicId);
      if (newTest.incompleteOld) {
        setViewState({
          ...viewState,
          testType,
          failedTestType,
          isCreateExamTestLoading: false,
          isCreateErrorTestLoading: false,
          isRegeneratingTest: false,
          test: newTest,
          showModal: true,
        });
      } else {
        navigateToTest(newTest.id);
      }
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: "Error al crear el test.",
      });
    }
  };

  const cleanAndGenerateNewTest = async () => {
    if (!viewState.testType || !viewState.test || !courseId) return;
    try {
      setViewState({ ...viewState, isRegeneratingTest: true });
      await terminateTest(viewState.test.id);
      onCreateTest(viewState.testType, viewState.failedTestType);
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: "Error al finalizar el test.",
      });
    }
  };

  if (isLoadingTestBySubject && isLoadingTestReview) {
    return <LoadingBar text={"Cargando tema ..."} />;
  }

  if (errorTestReview || errorTestBySubject) {
    return (
      <ToolBar>
        <p className="text-lg font-light">Ups, tuvimos un error al cargar los tests.</p>
      </ToolBar>
    );
  }

  const hasSubTopics = subject?.subtopics?.length !== 0;

  return (
    <>
      {courseId && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", { courseId: courseId! }),
            },
            {
              name: t("Header.Tests.topic"),
              path: getRoutePath("tests.subject", { courseId: courseId! }),
            },
            { name: subject?.title! },
          ]}
        />
      )}
      <SubjectReviewWrapper splitView={hasSubTopics}>
        {isLoadingTestBySubject ? <LoadingBar className="w-96" text={"Cargando sub temas ..."} /> : null}

        {!isLoadingTestBySubject && hasSubTopics ? (
          <div className="flex flex-col w-96 overflow-scroll">
            {subject?.subtopics ? <SubTopicCards subtopics={subject.subtopics} onSelect={(item) => setSelectedSubTopic(item)} activeSubtopic={subTopic?.id.toString()} /> : null}
          </div>
        ) : null}

        <div className="block w-full pt-0 p-5 flex-1">
          <div className="w-full min-h-[300px] flex flex-col space-y-10">
            {isLoadingTestReview ? (
              <LoadingBar text={"Cargando ..."} />
            ) : (
              <>
                <AvailableTestBar
                  isCreateEnabled={viewState.isCreateExamTestLoading || viewState.isCreateErrorTestLoading}
                  isCreateLoading={viewState.isCreateExamTestLoading}
                  onCreateTest={() => {
                    onCreateTest("subject");
                  }}
                  maxTest={subTopic ? subTopic.maxTest : subject?.maxTest}
                  testsDone={subTopic ? subTopic.testsDone : subject?.testsDone}
                  title="Tests disponibles"
                  ctaText="Hacer test"
                />

                <ExistentTestTable reviewCategory={testReview?.testCategories[0]} />
              </>
            )}
          </div>
        </div>
      </SubjectReviewWrapper>

      <FinishTestModal
        isOpen={viewState.showModal}
        isRegeneratingTest={viewState.isRegeneratingTest || viewState.isCreateErrorTestLoading || viewState.isCreateExamTestLoading}
        onClose={() =>
          setViewState({
            ...viewState,
            showModal: false,
          })
        }
        onContinue={() => {
          if (!viewState.test) return;
          navigateToTest(viewState.test.id);
        }}
        onRegenerate={cleanAndGenerateNewTest}
      />
    </>
  );
}
