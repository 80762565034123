import { onValue } from "firebase/database";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { saveSessionReply } from "../../../api/courses";
import { Answer, Content } from "../../../interfaces/courses";
import { meetingState } from "../../../state/meeting";
import { getReference } from "../../../utils/firebase";
import { ContentImage } from "../../ContentView/ContentImage";
import { ContentQuestion } from "../../ContentView/ContentQuestion";
import { ContentVideo } from "../../ContentView/ContentVideo";
import { getPreffixFirebase } from "../../../api/api";
import { getSession } from "../../../api/courses";
import { selectedSessionState } from "../../../state/global";

interface VideoStatus {
  seek?: number;
  status?: "play" | "pause";
}

export const ContentView = () => {
  const { t } = useTranslation();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const [content, setContent] = useState<Content | undefined>();
  const [videoStatus, setVideoStatus] = useState<VideoStatus>({});
  let { lessonId } = useParams();
  const [contentId, setContentId] = useState<string | undefined>();
  const [selectedLesson, setSelectedLesson] =
    useRecoilState(selectedSessionState);
  const noContentToShow = !content && meeting?.session?.type === "offline";
  const contentIsNotQuestion =
    meeting?.session?.type === "offline" && content?.type !== "question";

  if (
    lessonId === undefined &&
    meeting?.session?.sessionId !== undefined &&
    meeting?.session?.sessionId != null
  ) {
    lessonId = meeting?.session?.sessionId.toString();
  }

  const getLesson = useCallback(async () => {
    if (lessonId !== undefined) {
      const sessionResponse = await getSession(parseInt(lessonId, 10));
      setMeeting({ ...meeting, session: sessionResponse.session });
    }
  }, [lessonId]);

  /**
   * @description every time that a new contentID is detected, we search
   * in the content related to the session to set the content in the state
   */
  useEffect(() => {
    const content = meeting?.session?.content?.find(
      (c) => c?.id.toString() === contentId
    );
    // If content is null, maybe that lesson is changed, so, refresh
    if (content == null && lessonId !== undefined) {
      const sessionResponse = getSession(parseInt(lessonId));
      sessionResponse.then((result) => {
        setSelectedLesson(result.session);
        const content = result.session.content?.find(
          (c) => c?.id.toString() === contentId
        );
        setContent(content);
      });
    } else {
      setContent(content);
    }
  }, [contentId]);

  /**
   * @description This method has all the logic used to integrate with Firebase
   * and get the values for content and video status every time they are modified
   */
  useEffect(() => {
    const changePresentation = getReference(
      `changeDiapositiva${getPreffixFirebase()}/session${lessonId}`
    );
    const cancelPresentationChange = onValue(
      changePresentation,
      (snapshot: any) => {
        const data = snapshot.val();
        if (data?.activeForStudents) {
          setContentId(data?.diapositivaId);
          setVideoStatus({});
          if (data?.actualTemaClaseId !== undefined) {
            getLesson();
            setContentId(undefined);
          }
        } else {
          setContentId(undefined);
        }
      }
    );

    const changeVideo = getReference(
      `changeVideo${getPreffixFirebase()}/session${lessonId}`
    );
    const cancelVideoState = onValue(changeVideo, (snapshot: any) => {
      const data = snapshot.val();
      setVideoStatus((prevStatus) => {
        return { ...prevStatus, status: data?.action };
      });
    });

    const currentVideo = getReference(
      `currentVideo${getPreffixFirebase()}/session${lessonId}`
    );
    const cancelVideoProgress = onValue(
      currentVideo,
      (snapshot: any) => {
        const data = snapshot.val();
        setVideoStatus({ status: data?.state, seek: data?.second });
      },
      {
        onlyOnce: true,
      }
    );

    return () => {
      cancelPresentationChange();
      cancelVideoState();
      cancelVideoProgress();
    };
  }, [lessonId]);

  useEffect(() => {
    const sessionId = meeting?.session?.sessionId;
    const changePresentation = getReference(
      `changeDiapositiva${getPreffixFirebase()}/session${sessionId}`
    );
    const temaChange = onValue(changePresentation, (snapshot: any) => {
      const data = snapshot.val();
      if (data?.actualTemaClaseId !== undefined) {
      }
    });

    return () => {
      temaChange();
    };
  }, []);

  if (noContentToShow || contentIsNotQuestion) {
    return (
      <div className="h-full flex flex-col text-center justify-center">
        <h1 className="text-white font-bold text-2xl">
          {t("ContentView.pay-attention")}
        </h1>
      </div>
    );
  }

  if (!content) {
    return null;
  }

  /**
   * @description send the user answer to the API
   * @param answer user answer
   */
  const saveAnswer = async (contentId: number, answer?: Answer) => {
    if (!meeting?.session?.sessionId) return;
    await saveSessionReply(
      meeting.session.sessionId,
      contentId,
      answer ? answer.id.toString() : ""
    );
  };

  return (
    <div className="flex flex-col flex-grow p-2 w-11/12 max-h-full justify-center">
      {content.type === "image" && (
        <ContentImage imageUrl={content.image} text={content.explain} />
      )}
      {content.type === "video" && content.video && videoStatus?.status && (
        <ContentVideo
          videoUrl={content.video}
          seek={videoStatus?.seek}
          status={videoStatus.status}
        />
      )}
      {content.type === "question" && meeting?.session?.sessionId && (
        <ContentQuestion content={content} saveAnswer={saveAnswer} />
      )}
    </div>
  );
};
