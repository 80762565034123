import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import Spinner from "../Layout/Spinner";
import { ToolBar } from "../Layout/ToolBar";

export function AvailableTestBar({
  hasReduced = false,
  isCreateEnabled,
  isCreateLoading,
  onCreateTest,
  onCreateReducedTest,
  maxTest = 0,
  testsDone = 0,
  title,
  ctaText,
}: {
  title: string;
  ctaText: string;
  maxTest?: number;
  testsDone?: number;
  isCreateEnabled: boolean;
  isCreateLoading: boolean;
  hasReduced?: boolean;
  onCreateTest: () => void;
  onCreateReducedTest?: () => void;
}) {
  const { t } = useTranslation();
  return (
    <ToolBar>
      <div className="grow flex">
        <p>{title}</p>
        <span className="text-gray-medium ml-4 font-light">
          {maxTest - testsDone}
        </span>
        <span className="text-gray-medium ml-2 font-light text-xs self-center">
          {maxTest > 0 && testsDone > 0
            ? `(${t("Test.total-of")} ${maxTest})`
            : ""}
        </span>
      </div>
      <div className="flex space-x-5">
        {hasReduced && !isCreateLoading ? (
          <Button
            disabled={isCreateEnabled || maxTest - testsDone <= 0}
            onClick={onCreateReducedTest}
            className="text-sm py-2 px-4"
          >
            {t("Test.short-test")}
          </Button>
        ) : null}
        <Button
          disabled={isCreateEnabled || maxTest - testsDone <= 0}
          onClick={onCreateTest}
          className="text-sm py-2 px-4"
        >
          {isCreateLoading ? (
            <>
              <Spinner />
              {t("Test.creating-test")}
            </>
          ) : (
            ctaText
          )}
        </Button>
      </div>
    </ToolBar>
  );
}
