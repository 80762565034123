import { Listbox, Tab } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContent } from "../components/Classroom/TabContent";
import { ToolBar } from "../components/Layout/ToolBar";
import { useClassroom } from "../hooks/useClassroom";
import { SessionTabs } from "../interfaces/courses";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { getRoutePath } from "../utils/portalPages";
import { useParams } from "react-router-dom";
import { useUser } from "../hooks/useUser";

export function Classroom() {
  const { t } = useTranslation();
  const [sessions, setSessions] = useState<SessionTabs[]>([]);
  const { classrooms, getSessionTabs, selectedClassroom, setSelectedClassroom, isLoadingClassroom } = useClassroom();
  const { courseId } = useParams();
  const { setUserMetadata } = useUser();

  const loading = !isLoadingClassroom && classrooms?.length === 0 ? null : isLoadingClassroom;

  setUserMetadata({ currentPage: "Clases", currentLesson: null, currentTest: null });

  useEffect(() => {
    if (selectedClassroom) {
      setSessions(getSessionTabs(selectedClassroom));
    }
  }, [selectedClassroom]);

  return (
    <div className="flex flex-col space-y-5">
      {courseId && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", { courseId: courseId! }),
            },
            { name: t("Header.classes") },
          ]}
        />
      )}
      {!isLoadingClassroom && classrooms?.length === 0 ? null : (
        <ToolBar>
          {isLoadingClassroom ? (
            <p className="self-center">{t("Classroom.loading")}</p>
          ) : (
            <>
              <div className="flex flex-col justify-center">
                <p className="text-sm text-center sm:text-left">{selectedClassroom?.name}</p>
              </div>

              <Listbox value={selectedClassroom} onChange={setSelectedClassroom}>
                <div className="relative self-center">
                  <Listbox.Button className="relative rounded-md w-full sm:w-80 sm:text-sm border border-gray-light cursor-default bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-green-torcal focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                    <span className="block truncate">{t("Classroom.change-class")}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {classrooms &&
                      classrooms.map((classroom) => (
                        <Listbox.Option
                          key={classroom.id}
                          value={classroom}
                          className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-green-200" : "text-gray-900"}`}
                        >
                          {classroom.name}
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </>
          )}
        </ToolBar>
      )}

      {loading ? null : (
        <div className="flex flex-col w-full">
          <Tab.Group>
            <Tab.List>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      "w-full mt-2 mb-0 sm:w-60 text-center py-3 sm:mr-4 sm:mb-0 sm:rounded-t-lg",
                      selected ? "bg-green-torcal text-white" : "bg-gray-light text-gray-dark hover:bg-gray-400"
                    )}
                  >
                    {t("Classroom.actives")}
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      "w-full mt-2 mb-4 sm:w-60 text-center py-3 sm:mr-4 sm:mb-0 sm:rounded-t-lg",
                      selected ? "bg-green-torcal text-white" : "bg-gray-light text-gray-dark hover:bg-gray-400"
                    )}
                  >
                    {t("Classroom.outdated")}
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <TabContent lessons={sessions?.[0]?.sessions} showOptions={sessions?.[0]?.id === "active"} />
              </Tab.Panel>
              <Tab.Panel>
                <TabContent lessons={sessions?.[1]?.sessions} showOptions={sessions?.[1]?.id === "active"} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
    </div>
  );
}
