import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "../components/Layout/LoadingBar";
import { useCourses } from "../hooks/useCourses";
import { getRoutePath } from "../utils/portalPages";
import { useUser } from "../hooks/useUser";

export function Courses() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedCourse, courses, isLoadingCourses } = useCourses();
  const { setUserMetadata } = useUser();

  useEffect(() => {
    setSelectedCourse();
    setUserMetadata({ currentPage: "Cursos", currentLesson: null, currentTest: null });
  }, []);

  /**
   * @description if the user only have one course,
   * we redirect to the course lessons page
   */
  useEffect(() => {
    if (courses?.length === 1) {
      navigate(getRoutePath("course", { courseId: `${courses[0].id}` }));
    }
  }, [courses, navigate]);

  if (isLoadingCourses && (!courses || courses.length === 0)) {
    return <LoadingBar text={t("Courses.loading-courses")} />;
  }

  return (
    <div className="bg-white flex flex-col justify-start p-6 md:py-10 md:px-14 shadow-lg">
      {courses?.length === 0 && <p className="text-lg font-light mb-8">{t("Courses.no-courses-available")}</p>}
      {courses && courses.length > 1 && (
        <div className="flex flex-col w-full">
          <p className="text-lg font-light mb-8 text-left">{t("Courses.choose-the-course")}</p>

          <div className="flex flex-wrap justify-center md:justify-start md:gap-3">
            {courses?.map((c) => (
              <Link
                key={c.id}
                to={getRoutePath("course", { courseId: `${c.id}` })}
                className="bg-contain bg-no-repeat bg-center mt-10 md:mt-0 h-60 rounded-md flex flex-col justify-center w-72 border border-gray-medium hover:border-orange-600 shadow-lg group hover:shadow-neutral-400"
                style={{ backgroundImage: `url(${c.fotoPublicUrl})` }}
              >
                <p className="mt-auto text-white text-center text-sm bg-orange-torcal w-10/12 mx-auto rounded-md mb-4 py-2 group-hover:bg-orange-600">{c.title}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
