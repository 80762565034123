import classNames from "classnames";
import { ReactNode } from "react";
import { TestQuestion } from "../../../interfaces/tests";

export function PaginationButtons({
  currentIndex,
  setCurrentIndex,
  questions,
  isReview = false,
}: {
  currentIndex: number;
  setCurrentIndex: (value: number) => void;
  questions: TestQuestion[];
  isReview?: boolean;
}) {
  const correctAnswers: Record<number, number> = {};
  questions.forEach((q) => {
    const correctAnswer = q.answers.find((a) => a.correct === true);
    if (correctAnswer) correctAnswers[q.id] = correctAnswer?.id;
  });
  return (
    <>
      {questions.map((q, index) => (
        <div key={index} className="m-1">
          <Button
            key={index}
            onClick={() => setCurrentIndex(index)}
            haveDoubt={!!q.haveDoubt}
            isAnswered={!!q.answeredByUser}
            isReview={isReview}
            isSelected={currentIndex === index}
            isCorrect={q.answeredByUser === correctAnswers[q.id]}
            isWrong={q.answeredByUser !== undefined && q.answeredByUser !== correctAnswers[q.id]}
          >
            {index + 1}
          </Button>
        </div>
      ))}
    </>
  );
}

function Button({
  onClick,
  haveDoubt,
  isSelected,
  isAnswered,
  isReview,
  children,
  isCorrect,
  isWrong,
}: {
  onClick: () => void;
  haveDoubt: boolean;
  isSelected: boolean;
  isAnswered: boolean;
  isReview: boolean;
  children: ReactNode;
  isCorrect?: boolean;
  isWrong?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames("py-1 w-9 rounded-lg", isSelected ? "outline outline-offset-2 outline-orange-torcal" : "", {
        "bg-orange-300 hover:bg-orange-torcal": isReview && haveDoubt,
        "bg-gray-400": !isReview && isSelected && !isAnswered,
        "bg-gray-600": !isReview && isSelected && isAnswered,
        "bg-gray-500 hover:bg-gray-700": !isReview && isAnswered,
        "bg-gray-300 hover:bg-gray-400": !isReview && !isSelected && !isAnswered,
        "bg-green-torcal hover:bg-green-800": isReview && isCorrect,
        "bg-red-500 hover:bg-red-800": (isReview && !isAnswered) || (isAnswered && isReview && isWrong),
      })}
      style={isReview && !isAnswered ? { boxShadow: "inset 0px 0px 0px 4px #D1D5DB" } : {}}
    >
      {children}
    </button>
  );
}
