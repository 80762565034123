import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useUser } from "../../../hooks/useUser";
import { chatNotificationStatus, chatViewState } from "../../../state/meeting";
const { usePubSub } = require("@videosdk.live/react-sdk");

interface VideoSDKMessage {
  id: string;
  message: string;
  senderId: string;
  senderName: string;
  timestamp: string;
  topic: string;
}

const VIDEO_SDK_TOPIC = "CHAT";

function getMessageTime(timestamp: string): string {
  const date = new Date(timestamp);
  return `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
    date.getMinutes() < 10 ? "0" : ""
  }${date.getMinutes()}`;
}

export const ChatView = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const chatState = useRecoilValue(chatViewState);
  const setNotifyNewMessage = useSetRecoilState(chatNotificationStatus);
  const messagesContainer = useRef<HTMLHeadingElement>(null);
  const [message, setMessage] = useState<string>("");
  const pubSub = usePubSub(VIDEO_SDK_TOPIC);

  const scrollChatToBottom = () =>
    setTimeout(() => {
      if (messagesContainer.current) {
        messagesContainer.current.scrollTop =
          messagesContainer.current.scrollHeight;
      }
    }, 300);

  const sendChat = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!message || message?.length === 0) return;

    try {
      pubSub.publish(message, { persist: true });
      setMessage("");
      scrollChatToBottom();
    } catch (error) {}
  };

  /**
   * @description every time that the messages in the meeting change,
   * we render all of them, check if the last message receive is from another
   * participant and if it is we notify the user.
   * At the end we move the div to the bottom and
   */
  useEffect(() => {
    const lastMessage: VideoSDKMessage = pubSub?.messages?.slice(-1)[0];
    if (
      lastMessage?.topic === VIDEO_SDK_TOPIC &&
      lastMessage.senderId !== user?.username
    ) {
      new Audio(`/notification.mp3`).play();
      setNotifyNewMessage(true);
    }
    scrollChatToBottom();
  }, [pubSub?.messages]);

  if (chatState === "closed") return null;

  return (
    <div className="flex flex-col bg-gray-300 pt-4 max-w-96 w-96">
      <div className="flex-grow overflow-y-scroll" ref={messagesContainer}>
        {pubSub?.messages
          .filter((m: VideoSDKMessage) => m.topic === VIDEO_SDK_TOPIC)
          .map((message: VideoSDKMessage) => (
            <div
              key={`${message.timestamp}-${message.senderId}`}
              id={`${message.senderId}-${user?.username}`}
              className={classNames(
                "mb-2 p-2",
                message.senderId === user?.username
                  ? "bg-green-torcal opacity-80 text-right ml-4 mr-1"
                  : "bg-orange-torcal opacity-80 text-left mr-4 ml-1"
              )}
            >
              <p className="text-gray-900 text-sm">
                {message.senderId === user?.username
                  ? t("ChatView.me")
                  : message.senderName}{" "}
                <span className="italic">{t("ChatView.receive-at")}</span>{" "}
                {getMessageTime(message.timestamp)}
              </p>
              <p className="text-white">{message.message}</p>
            </div>
          ))}
      </div>
      <form
        onSubmit={sendChat}
        className="my-4 pr-2 self-end flex w-full justify-center gap-3"
      >
        <input
          type="text"
          className="flex-grow ml-1 pl-2 text-sm"
          value={message}
          placeholder={t("ChatView.placeholder")}
          onChange={(ev) => {
            setMessage(ev.target.value);
          }}
        />
        <button
          type="submit"
          className="hover:bg-slate-500 rounded-full p-2"
          disabled={!message || message?.length === 0}
        >
          <PaperAirplaneIcon
            className={classNames(
              "w-5 h-5",
              !message || message?.length === 0 ? "text-gray-500" : "text-white"
            )}
          />
        </button>
      </form>
    </div>
  );
};
