import { atom } from "recoil";
import {
  ClassRoom,
  Course,
  Lesson,
  LessonContent,
  LessonContentContent,
  Session,
} from "../interfaces/courses";

export const coursesState = atom<Course[]>({
  key: "coursesState",
  default: [],
});

export const lessonsState = atom<Lesson[]>({
  key: "lessonsState",
  default: [],
});

export const selectedCourseState = atom<Course | undefined>({
  key: "selectedCourseState",
  default: undefined,
});

export const selectedSessionState = atom<Session | undefined>({
  key: "selectedSessionState",
  default: undefined,
});

export const selectedClassroomState = atom<ClassRoom | undefined>({
  key: "selectedClassroomState",
  default: undefined,
});

export const lessonContentState = atom<LessonContent | undefined>({
  key: "lessonContentState",
  default: undefined,
});

export const selectedLessonContentState = atom<
  LessonContentContent | undefined
>({
  key: "selectedLessonContentState",
  default: undefined,
});
