import { ChatBubbleOvalLeftIcon, MicrophoneIcon, PhoneIcon, HandRaisedIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { chatNotificationStatus, chatViewState, meetingState, raiseHandStatus } from "../../../state/meeting";
import { getRoutePath } from "../../../utils/portalPages";
import { MeetingButton } from "../../Presentational/Meeting/MeetingButton";
import { selectedSessionState } from "../../../state/global";
import { useCourses } from "../../../hooks/useCourses";
import { useSDKMeeting } from "../../../context/SdkMeetingContext";

const { useMeeting, useParticipant, usePubSub } = require("@videosdk.live/react-sdk");

/**
 * @description this component is in charge of render the meeting buttons
 * according the lesson type, it could be online of offline
 */

interface VideoControllerProps {
  modeComplete?: boolean;
}

export const VideoController = ({ modeComplete = true }: VideoControllerProps) => {
  const { t } = useTranslation();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const [chatState, setChatState] = useRecoilState(chatViewState);
  const [raiseHand, setRaiseHand] = useRecoilState(raiseHandStatus);
  const [chatShowNotification, setChatShowNotification] = useRecoilState(chatNotificationStatus);
  const { selectedCourse } = useCourses();
  const navigate = useNavigate();
  const micRef = useRef<any>(null);
  const { micStream, micOn } = useParticipant(meeting?.teacherId ?? "");
  const { sdkMeeting, setSDKMeeting } = useSDKMeeting();

  const SDKMeeting = useMeeting({
    // We automatically activate the microphone when receiving a request
    onMicRequested: (e: any) => {
      SDKMeeting.unmuteMic();
      setRaiseHand(false);
      pubSub.publish("UnRaise Hand");
    },
  });
  const pubSub = usePubSub("RAISE_HAND");
  const [selectedLesson, setSelectedLesson] = useRecoilState(selectedSessionState);

  useEffect(() => {
    setSDKMeeting(SDKMeeting);
  }, [SDKMeeting, setSDKMeeting]);

  /*
  TODO: Esto no se usa, se usa el que hay en ParticipantAudioPlayer.tsx

  useEffect(() => {
    if (micRef.current) {
      if (micOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream);

        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error: any) =>
          // eslint-disable-next-line no-console
          console.error("videoElem.current.play() failed", error)
        );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);
  */

  usePubSub("LOWER_ALL_HANDS", {
    onMessageReceived: () => {
      setRaiseHand(false);
    },
  });

  const toggleMic = () => {
    setMeeting({
      ...meeting,
      micEnabled: !SDKMeeting?.localMicOn,
    });
    if (SDKMeeting?.localMicOn) {
      SDKMeeting.muteMic();
    } else {
      SDKMeeting.unmuteMic();
    }
  };

  const toggleRaiseHand = () => {
    if (raiseHand) {
      pubSub.publish("UnRaise Hand");
    } else {
      pubSub.publish("Raise Hand");
    }
    setRaiseHand(!raiseHand);
  };

  const toggleChat = () => {
    setChatState(chatState === "open" ? "closed" : "open");
    setChatShowNotification(false);
  };

  const leaveMeeting = () => {
    if (meeting?.session?.type === "online") {
      if (sdkMeeting) {
        sdkMeeting.leave();
        setMeeting(undefined);
        setSelectedLesson(undefined);
      }
    }
    //console.log("Leave");
    const courseId = selectedCourse?.id.toString();
    navigate(getRoutePath("classroom", { courseId: `${courseId}` }));
  };

  /*
  useEffect(() => {
    return () => {
      leaveMeeting();
    };
  }, []);
  */

  if (modeComplete) {
    return (
      <div className="absolute w-full flex justify-center bottom-6 gap-4">
        {meeting?.session?.type === "online" ? (
          <>
            <MeetingButton
              title={t(SDKMeeting?.localMicOn ? "VideoController.disableMic" : "VideoController.enableMic")}
              onClick={toggleMic}
              disabled={true}
              className={SDKMeeting?.localMicOn ? "bg-gray-50" : "bg-red-400"}
            >
              <MicrophoneIcon className={classNames("h-6", SDKMeeting?.localMicOn ? "text-black" : "text-white")} />
            </MeetingButton>

            <MeetingButton
              title={t(raiseHand ? "VideoController.unraiseHand" : "VideoController.raiseHand")}
              onClick={toggleRaiseHand}
              className={!raiseHand ? "bg-gray-50" : "bg-red-400"}
            >
              <HandRaisedIcon className={classNames("h-6", !raiseHand ? "text-black" : "text-white")} />
            </MeetingButton>

            <MeetingButton
              title={t(chatState === "open" ? "VideoController.closeChat" : "VideoController.openChat")}
              className={chatState === "open" ? "bg-gray-50 text-black" : "bg-gray-400 text-white"}
              onClick={toggleChat}
            >
              <ChatBubbleOvalLeftIcon className="h-6" />
              {chatShowNotification && chatState === "closed" && (
                <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-4 h-4 bg-orange-torcal rounded-full border-2 border-white" />
              )}
            </MeetingButton>
          </>
        ) : null}

        <MeetingButton
          title={t("VideoController.leave")}
          onClick={leaveMeeting}
          className={classNames("rounded-full p-2 hover:bg-gray-300 text-black bg-gray-50", meeting?.session?.type === "online" ? "ml-5" : "")}
        >
          <PhoneIcon className={classNames("h-6 rotate-[135deg]")} />
        </MeetingButton>
      </div>
    );
  } else {
    return (
      <div className="absolute w-full flex bottom-2 gap-1" style={{ marginLeft: 10 }}>
        <MeetingButton
          title={t(raiseHand ? "VideoController.unraiseHand" : "VideoController.raiseHand")}
          onClick={toggleRaiseHand}
          className={!raiseHand ? "bg-gray-50" : "bg-red-400"}
        >
          <HandRaisedIcon className={classNames("h-5", !raiseHand ? "text-black" : "text-white")} />
        </MeetingButton>
      </div>
    );
  }
};
