import classNames from "classnames";

interface MeetingButtonProps {
  title?: string;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const MeetingButton = ({
  title,
  disabled = false,
  onClick,
  children,
  className,
}: MeetingButtonProps) => {
  return (
    <button
      disabled={disabled}
      type="button"
      aria-label={title}
      title={title}
      onClick={onClick}
      className={classNames(
        "inline-flex relative items-center p-3 rounded-3xl hover:bg-gray-300 focus:ring-4 focus:outline-none",
        className
      )}
    >
      {children}
    </button>
  );
};
