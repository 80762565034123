import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ChatMessage } from "../../interfaces/chat";

export default function Message({
  userName,
  message,
  parentMessage,
  onRespond,
}: {
  userName: string;
  message: ChatMessage;
  parentMessage?: ChatMessage;
  onRespond: (m: ChatMessage) => void;
}) {
  const isUserAuthor = userName === message.author;

  return (
    <div
      className={classNames(
        "relative text-white flex flex-col w-3/4 mt-3 p-3 rounded-3xl",
        isUserAuthor
          ? "bg-green-torcal self-end rounded-br-none"
          : "bg-orange-torcal rounded-tl-none"
      )}
    >
      {isUserAuthor ? null : (
        <p className="text-left text-sm font-bold">
          {message.attributes.usernameForDisplay}
        </p>
      )}

      <ParentMessage msg={parentMessage} />

      {message.attributes.msgType === "question" ? (
        <QuestionMessage msg={message} />
      ) : (
        <p className="text-left text-sm mt-1">{message.body}</p>
      )}

      <p className="text-right mt-2 text-xs text-gray-dark">
        {new Date(message.dateCreated).toLocaleString()}
      </p>

      {!isUserAuthor ? (
        <RespondButton msg={message} onRespond={onRespond} />
      ) : null}
    </div>
  );
}

function ParentMessage({ msg }: { msg?: ChatMessage }) {
  if (!msg) return null;
  return (
    <div className="bg-green-700 flex flex-col w-full p-2 rounded-lg mb-2 -my-1">
      <p className="text-left text-xs font-bold">
        {msg.attributes.usernameForDisplay}
      </p>
      <p className="text-left text-sm mt-1 truncate">{msg.body}</p>
    </div>
  );
}

function RespondButton({
  onRespond,
  msg,
}: {
  onRespond: (m: ChatMessage) => void;
  msg: ChatMessage;
}) {
  const { t } = useTranslation();
  return (
    <button
      className="rounded-full bg-gray-300 hover:bg-gray-500 p-2 absolute -right-11 group"
      onClick={() => {
        onRespond(msg);
      }}
    >
      <ArrowUturnLeftIcon className="h-4" />
      <div className="absolute -left-5 top-10 items-center hidden mb-6 group-hover:flex flex-grow">
        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
          {t("Chat.reply")}
        </span>
        <div className="absolute left-8 w-3 h-3 -top-1 rotate-45 bg-black"></div>
      </div>
    </button>
  );
}

function QuestionMessage({ msg }: { msg?: ChatMessage }) {
  if (!msg) return null;

  return (
    <>
      <div className="flex w-full">
        <img
          src={msg.attributes.image}
          alt={msg.body}
          className="w-1/3 rounded-xl mr-2"
        />
        <p className="text-left text-sm font-bold">{msg.body}</p>
      </div>
      <ul className="list-disc list-inside mt-2">
        {msg.attributes.answers &&
          msg.attributes.answers.map((a) => (
            <li key={a} className="text-sm truncate">
              {a}
            </li>
          ))}
      </ul>
    </>
  );
}
