import classNames from "classnames";
import { ReactNode } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { routesPath } from "../../interfaces/portalPages";
import Header from "./Header";

/**
 * @description this component is the one who orchestrate the view layout
 */
export default function Layout({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const isMeetingPage = !!matchPath(routesPath["lesson-meeting"], pathname);
  const isFullWidth = false;

  const getWrapperStyles = (): string => {
    if (isMeetingPage) return "max-h-screen";
    if (isFullWidth) return "w-full";

    return "2xl:max-w-[1440px]";
  };

  return (
    <div className="min-w-full flex flex-col min-h-screen bg-stone-100">
      <Header />
      <div
        className={classNames(
          "flex flex-grow flex-col w-full mx-auto",
          getWrapperStyles()
        )}
      >
        <main
          className={classNames(
            "flex flex-col grow m-6",
            isFullWidth ? "w-full m-0" : "pb-32 md:mx-9"
          )}
        >
          {children}
        </main>
      </div>
    </div>
  );
}
