import { Lesson } from "../interfaces/courses";
import { TestBySubject, TestInfo } from "../interfaces/tests";

export const getLessonPercentage = (lesson: Lesson): number => {
  if (lesson.contentsDone === 0) return 0;
  const percentage = Math.round(
    (lesson.contentsDone / lesson.maxContent) * 100
  );

  return percentage > 100 ? 100 : percentage;
};

export const getTestPercentage = <Type extends TestBySubject | TestInfo>(
  test: Type
): number => {
  if (test.testsDone === 0) return 0;
  const percentage = Math.round((test.testsDone / test.maxTest) * 100);
  return percentage > 100 ? 100 : percentage;
};
