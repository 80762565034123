import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import {
  CourseResponse,
  LessonResponse,
  Session,
  SessionResponse,
  SessionScreen,
} from "../interfaces/courses";
import { CustomError } from "../utils/CustomError";
import { apiFetch } from "./api";

export async function getStudentCourses(): Promise<CourseResponse> {
  try {
    return await apiFetch("/student/selectionType", "GET");
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function getClassrooms(): Promise<SessionScreen> {
  try {
    const classRoomResponse: LessonResponse = await apiFetch(
      "/classroom/sessions",
      "GET"
    );

    return classRoomResponse.sessionScreen;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function getSession(sessionId: number): Promise<SessionResponse> {
  try {
    const sessionResponse: SessionResponse = await apiFetch(
      `/classroom/session/${sessionId}`,
      "GET"
    );
    if (sessionResponse.session.state == "in_progress") return sessionResponse;
    throw "The teacher closed the classroom...";
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function saveSessionReply(
  sessionId: number,
  contentId: number,
  answerId: string
): Promise<SessionResponse> {
  try {
    return await apiFetch(
      `/classroom/session/reply?sessionId=${sessionId}&replyIdCmitest=${answerId}&diapositivaIdCmitest=${contentId}`,
      "PUT"
    );
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}
