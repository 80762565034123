import { useTranslation } from "react-i18next";
import { useLesson } from "../../hooks/useLesson";
import { Answer } from "../../interfaces/courses";
import { Button } from "../Button";
import { ContentImage } from "../ContentView/ContentImage";
import { ContentQuestion } from "../ContentView/ContentQuestion";
import { ContentVideo } from "../ContentView/ContentVideo";

export function ContentWrapper() {
  const { t } = useTranslation();
  const { selectedContent, lessonContent, setSelectedContent, answerQuestion } =
    useLesson();
  const saveAnswer = (contentId: number, answer?: Answer) => {
    if (!selectedContent || !answer) return;
    answerQuestion(selectedContent, answer.id);
  };

  const nextContent = async () => {
    if (!selectedContent || !lessonContent) return;
    const indexOf = lessonContent.content.indexOf(selectedContent);
    if (
      selectedContent.viewed &&
      indexOf !== undefined &&
      indexOf >= 0 &&
      lessonContent.content[indexOf + 1]
    ) {
      return setSelectedContent(lessonContent.content[indexOf + 1]);
    }
  };

  return (
    <div className="block w-full pt-0 p-5 flex-1 space-y-7">
      <div className="p-5 w-full min-h-[800px] flex flex-col bg-white overflow-scroll">
        <div className="grow">
          {selectedContent?.type === "image" && (
            <ContentImage
              imageUrl={selectedContent?.image}
              text={selectedContent?.explain}
            />
          )}
          {selectedContent?.type === "video" && selectedContent?.video && (
            <ContentVideo
              videoUrl={selectedContent?.video}
              seek={0}
              status="play"
              showControls={true}
            />
          )}
          {selectedContent?.type === "question" && (
            <ContentQuestion
              content={selectedContent}
              saveAnswer={saveAnswer}
              showAnswer
            />
          )}
        </div>
        <div className="flex justify-between mt-auto">
          <div className="flex space-x-3 items-stretch"></div>
          <Button onClick={nextContent}>{t("Content.next")}</Button>
        </div>
      </div>
    </div>
  );
}
