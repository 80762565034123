import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { ProgressCard } from "../components/Card/ProgressCard";
import LoadingBar from "../components/Layout/LoadingBar";
import Spinner from "../components/Layout/Spinner";
import { ToolBar } from "../components/Layout/ToolBar";
import { FinishTestModal } from "../components/Test/FinishTestModal";
import { useCourses } from "../hooks/useCourses";
import { useTest } from "../hooks/useTest";
import { useTests } from "../hooks/useTests";
import { CreatedTest, TestBySubject as TestBySubjectI } from "../interfaces/tests";
import { getTestPercentage } from "../utils/Percentage";
import { getRoutePath } from "../utils/portalPages";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useUser } from "../hooks/useUser";

interface ViewState {
  error?: string;
  showModal: boolean;
  isRegeneratingTest: boolean;
  isCreateErrorTestLoading: boolean;
  test?: CreatedTest;
  testType?: "exam" | "subject" | "review" | "failed";
  failedTestType?: "exam" | "subject" | "review";
}

export function TestsBySubject() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewState, setViewState] = useState<ViewState>({
    showModal: false,
    isRegeneratingTest: false,
    isCreateErrorTestLoading: false,
  });
  const { isLoadingTestBySubject, testBySubject, infoSubject } = useTests();
  const { createTest, terminateTest } = useTest();
  const { selectedCourse } = useCourses();
  const [filter, setFilter] = useState<"all" | "incomplete">("all");
  const { setUserMetadata } = useUser();

  setUserMetadata({ currentPage: "Tests por temas", currentLesson: null, currentTest: null });

  const changeFilter = (event: React.FormEvent<HTMLSelectElement>) => {
    // @ts-ignore
    setFilter(event.target.value);
  };

  const onCreateTest = async (testType: "exam" | "subject" | "review" | "failed", failedTestType?: "exam" | "subject" | "review") => {
    if (!selectedCourse) return;

    try {
      setViewState({
        ...viewState,
        testType,
        failedTestType,
        isCreateErrorTestLoading: !!(testType && failedTestType),
      });

      const newTest = await createTest(selectedCourse.id.toString(), testType, failedTestType);
      if (newTest.incompleteOld) {
        setViewState({
          ...viewState,
          testType,
          failedTestType,
          isCreateErrorTestLoading: false,
          isRegeneratingTest: false,
          test: newTest,
          showModal: true,
        });
      } else {
        navigateToTest(newTest.id);
      }
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error"),
      });
    }
  };

  const cleanAndGenerateNewTest = async () => {
    if (!viewState.testType || !viewState.test || !selectedCourse) return;
    try {
      setViewState({ ...viewState, isRegeneratingTest: true });
      await terminateTest(viewState.test.id);
      onCreateTest(viewState.testType, viewState.failedTestType);
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error-finishing-test"),
      });
    }
  };

  const filteredTests = filter === "all" ? testBySubject : testBySubject?.filter((t) => t.testsDone !== t.maxTest);

  const navigateToDetails = (test: TestBySubjectI) => {
    if (!selectedCourse) return;
    navigate(
      getRoutePath("tests.subject.review", {
        courseId: selectedCourse.id.toString(),
        subjectId: test.id.toString(),
      })
    );
  };

  const navigateToTest = (testId: string): void => {
    if (!selectedCourse) return;
    window.localStorage.setItem("test-dashboard", location.pathname);

    navigate(
      getRoutePath("tests.details", {
        courseId: selectedCourse.id.toString(),
        testId,
      })
    );
  };

  if (isLoadingTestBySubject) {
    return <LoadingBar text={t("Test.loading-tests")} />;
  }

  if (testBySubject?.length === 0) {
    return (
      <ToolBar>
        <p className="text-lg font-light">{t("Test.no-tests-available")}</p>
      </ToolBar>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      {selectedCourse && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", {
                courseId: selectedCourse?.id.toString()!,
              }),
            },
            { name: t("Header.Tests.topic") },
          ]}
        />
      )}
      <ToolBar>
        {!infoSubject ? null : (
          <>
            <p className="grow leading-10 pr-10">
              {t("Test.not-available-test-error")}
              <span className="text-gray-medium ml-4 font-light">{infoSubject.testsFalloPending}</span>
            </p>
            <Button
              disabled={infoSubject.testsFalloPending === 0 || viewState.isCreateErrorTestLoading}
              onClick={() => {
                onCreateTest("failed", "subject");
              }}
              className="text-sm py-2 px-4"
            >
              {viewState.isCreateErrorTestLoading ? (
                <>
                  <Spinner />
                  {t("Test.creating")}
                </>
              ) : (
                t("Test.create-test-error")
              )}
            </Button>
          </>
        )}
      </ToolBar>

      <div className="bg-white flex flex-col justify-start p-6 md:py-10 md:px-14 shadow-lg">
        <div>
          <span className="mr-4">{t("Test.filter-by")}</span>
          <select className="w-full sm:w-56" onChange={changeFilter}>
            <option value="all">{t("Test.all-options")}</option>
            <option value="notFinished">{t("Test.incomplete-options")}</option>
          </select>
        </div>
        <hr className="my-5" />
        <div className="flex flex-col w-full">
          <div className="grid grid-cols-1 justify-items-center sm:justify-items-start gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {filteredTests?.map((test) => (
              <ProgressCard key={test.id} item={test} onClick={navigateToDetails} progress={getTestPercentage(test)} />
            ))}
          </div>
        </div>
      </div>

      <FinishTestModal
        isOpen={viewState.showModal}
        isRegeneratingTest={viewState.isRegeneratingTest || viewState.isCreateErrorTestLoading}
        onClose={() =>
          setViewState({
            ...viewState,
            showModal: false,
          })
        }
        onContinue={() => {
          if (!viewState.test) return;
          navigateToTest(viewState.test.id);
        }}
        onRegenerate={cleanAndGenerateNewTest}
      />
    </div>
  );
}
