import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCourses } from "../../hooks/useCourses";
import { usePagination } from "../../hooks/usePagination";
import { Session } from "../../interfaces/courses";
import { getRoutePath } from "../../utils/portalPages";
import { Table } from "../Table/Table";

export function TabContent({
  lessons,
  showOptions,
}: {
  lessons?: Session[];
  showOptions: boolean;
}) {
  const { t } = useTranslation();
  const { selectedCourse } = useCourses();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pagination = usePagination(
    lessons ?? [],
    lessons?.length ?? 0,
    10,
    currentPage
  );

  return (
    <div>
      <Table>
        <Table.Head>
          <tr>
            <th scope="col" className="py-3 px-6 w-7">
              {t("Classroom.room")}
            </th>
            <th scope="col" className="py-3 px-6 font-normal text-center">
              {t("Classroom.date")}
            </th>
            <th scope="col" className="py-3 px-6 font-normal text-center">
              {t("Classroom.hour")}
            </th>
            <th scope="col" className="py-3 px-6 font-normal"></th>
          </tr>
        </Table.Head>
        <Table.Body>
          {pagination.items?.length === 0 ? (
            <tr key="empty" className="bg-white hover:bg-gray-50 h-[70px]">
              <th
                scope="row"
                colSpan={4}
                className="py-4 px-6 text-center whitespace-nowrap"
              >
                {t("Classroom.no-class")}
              </th>
            </tr>
          ) : (
            pagination.items.map((lesson) => (
              <tr
                key={lesson.sessionId}
                className="bg-white border-b hover:bg-gray-50 h-[70px] last:border-0"
              >
                <th
                  key={`${lesson.sessionId}-title`}
                  scope="row"
                  className="py-4 px-6 text-center font-medium text-gray-900 whitespace-nowrap"
                >
                  {lesson.title}
                </th>
                <td
                  key={`${lesson.sessionId}-day`}
                  className="py-4 px-6 text-center"
                >
                  {lesson.date}
                </td>
                <td
                  key={`${lesson.sessionId}-time`}
                  className="py-4 px-6 text-center"
                >
                  {lesson.subTitle}
                </td>
                <td
                  key={`${lesson.sessionId}-options`}
                  className="py-4 px-6 grid justify-items-end"
                >
                  {showOptions ? (
                    <Link
                      to={getRoutePath("lesson-meeting", {
                        courseId: selectedCourse?.id.toString() ?? "",
                        lessonId: lesson.sessionId.toString(),
                      })}
                      className="bg-green-torcal hover:bg-green-700 px-3 text-sm flex py-2 justify-center border border-transparent text-md font-medium rounded-md text-white disabled:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-torcal"
                    >
                      {t("Classroom.go")}
                    </Link>
                  ) : null}
                </td>
              </tr>
            ))
          )}
        </Table.Body>
      </Table>

      {pagination.items.length > 0 ? (
        <div className="mt-4 flex justify-between">
          <span className="text-xs self-center">{pagination.description}</span>
          {pagination.totalPages > 0 ? (
            <div className="grow text-right space-x-3">
              <button
                className="bg-white hover:bg-white/40 w-8 h-8 align-center disabled:bg-gray-300"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ChevronLeftIcon className="h-6 fill-green-torcal mx-auto" />
              </button>
              <button
                className="bg-white hover:bg-white/40 w-8 h-8 disabled:bg-gray-300 "
                disabled={currentPage === pagination.totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ChevronRightIcon className="h-6 fill-green-torcal mx-auto" />
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
