import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @description This component renders an image and it's description
 */
export const ContentImage = ({
  imageUrl,
  text,
}: {
  imageUrl: string;
  text?: string;
}) => {
  const { t } = useTranslation();
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  useEffect(() => {
    setLoadingImage(true);
  }, [imageUrl]);

  return (
    <>
      <img
        className={classNames(
          loadingImage ? "hidden" : "h-5/6 object-contain m-auto"
        )}
        src={imageUrl}
        alt={text}
        onLoad={() => setLoadingImage(false)}
      />
      {loadingImage && (
        <div className="h-52 py-2 w-full text-center flex flex-col justify-center text-gray-medium">
          <span>{t("Content.loading-image")}</span>
        </div>
      )}
    </>
  );
};
