import classNames from "classnames";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Answer, Content, LessonContentContent } from "../../interfaces/courses";
import { TestQuestion } from "../../interfaces/tests";
import { ImageModal } from "./ImageModal";

const OPTIONS_LETTERS = ["A", "B", "C", "D", "E", "F"];

export const ContentQuestion = ({
  content,
  saveAnswer,
  showAnswer = true,
  isReview = false,
  numberOfQuestion,
}: {
  content: Content | LessonContentContent | TestQuestion;
  saveAnswer: (contentId: number, answer?: Answer) => void;
  showAnswer?: boolean;
  isReview?: boolean;
  numberOfQuestion?: number;
}) => {
  const { t } = useTranslation();
  let [showImageModal, setShowImageModal] = useState(false);
  const [userAnswer, setUserAnswer] = useState<Answer | undefined>();
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  useEffect(() => {
    if (content.answeredByUser) {
      const answer = content.answers?.find((a) => a.id === content.answeredByUser);
      setUserAnswer(answer);
    } else {
      setUserAnswer(undefined);
    }
  }, [content.id]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [content.id, userAnswer?.id]);

  const getAnswerColor = (a: Answer): string => {
    if (!isReview && !userAnswer) return "bg-gray-300";
    if (!showAnswer) {
      if (a.id === userAnswer?.id) return "bg-orange-torcal";
    } else {
      const itIsCorrect = (userAnswer?.correct && a.id === userAnswer?.id) || a.correct;
      const itIsWrong = a.id === userAnswer?.id && !userAnswer.correct;

      if (itIsCorrect) return "bg-green-torcal";
      if (itIsWrong) return "bg-red-500";
    }
    return "bg-gray-300";
  };

  const chooseAnswer = (contentId: number, a: Answer | null) => {
    if (a) {
      saveAnswer(contentId, a.id === userAnswer?.id ? undefined : a);
      setUserAnswer(a.id === userAnswer?.id ? undefined : a);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const { key } = event;
    const answerIndex = OPTIONS_LETTERS.indexOf(key.toUpperCase());
    if (answerIndex < 0) return;
    if (isReview || (showAnswer && userAnswer)) return;

    const answer = content.answers ? content.answers[answerIndex] : null;
    chooseAnswer(content.id, answer);
  };

  const defaultImage = "/default-image.jpg";
  const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    target.src = defaultImage;
  };

  return (
    <>
      <div className="flex items-center w-full">
        <button
          className="w-96"
          onClick={() => {
            setShowImageModal(true);
          }}
        >
          <img
            className={classNames(loadingImage ? "hidden" : "w-full h-auto object-contain")}
            src={content.image}
            alt={content.explain}
            onLoad={() => setLoadingImage(false)}
            onError={handleImageError}
          />
          {loadingImage && (
            <div className="py-2 w-full h-full text-center flex flex-col justify-center text-gray-medium">
              <span>{t("Content.loading-image")}</span>
            </div>
          )}
          <p className="text-xs text-gray-400">{t("Content.image-zoom")}</p>
          <ImageModal
            imageSrc={content.image}
            isOpen={showImageModal}
            onClose={() => {
              setShowImageModal(false);
            }}
          />
        </button>

        <div className="grow mx-auto pl-4">
          <h5 className="mb-10 text-xl text-gray-800">
            {numberOfQuestion && (
              <span className="pl-4 pr-4 pb-1 pt-1 bg-gray-300 leading-10 rounded-[0.80rem] w-10 h-6 mr-3 text-xl border-2 border-orange-300">{numberOfQuestion}</span>
            )}
            {content.question}
          </h5>
          <ul className="my-4 space-y-3">
            {content.answers?.map((a, index) =>
              a.answer ? (
                <li key={a.id}>
                  <button
                    className="flex leading-10"
                    onClick={() => {
                      if (isReview || (showAnswer && userAnswer)) return;
                      chooseAnswer(content.id, a);
                    }}
                  >
                    <div className={classNames("shrink-0 font-thin leading-10 rounded-full w-10 h-10 mr-3 text-xl hover:shadow hover:text-gray-600", getAnswerColor(a))}>
                      {OPTIONS_LETTERS[index]}
                    </div>
                    <span className="text-left">{a.answer}</span>
                  </button>
                </li>
              ) : null
            )}
          </ul>
        </div>
      </div>
      {isReview && !content.answeredByUser ? (
        <div className="text-center text-red-500" style={{ boxShadow: "inset 0px 0px 0px 4px #D1D5DB" }}>
          *La pregunta no fue contestada
        </div>
      ) : null}
    </>
  );
};
