import { selectorFamily } from "recoil";
import { getTestInfo, getTestReview, getTestsBySubject } from "../api/tests";
import { TestInfoResponse } from "../interfaces/apiResponses";
import { TestBySubject, TestReview } from "../interfaces/tests";

export const testInfoState = selectorFamily<
  TestInfoResponse | undefined,
  string | undefined
>({
  key: "testInfoState",
  get:
    (courseId) =>
    async ({ get }) => {
      if (!courseId) return undefined;
      const response = await getTestInfo(courseId);

      return response;
    },
});

export const testReviewState = selectorFamily<
  TestReview | undefined,
  {
    courseId: string | undefined;
    subjectId: string | undefined;
    subtopicId: string | undefined;
  }
>({
  key: "testReviewState",
  get:
    (data) =>
    async ({ get }) => {
      if (!data.courseId) return undefined;
      if (data.subjectId || data.subtopicId) {
        return await getTestReview(
          data.courseId,
          "subject",
          data.subjectId,
          data.subtopicId
        );
      }
      return await getTestReview(data.courseId);
    },
});

export const testBySubjectState = selectorFamily<
  TestBySubject[] | undefined,
  string | undefined
>({
  key: "testBySubjectState",
  get:
    (courseId) =>
    async ({ get }) => {
      if (!courseId) return undefined;
      return await getTestsBySubject(courseId);
    },
});
