/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, matchRoutes, useLocation, useParams } from "react-router-dom";
import { performTest } from "../api/tests";
import LoadingBar from "../components/Layout/LoadingBar";
import { TestView } from "../components/Test/TestView";
import { routesPath } from "../interfaces/portalPages";
import { Test as TestI } from "../interfaces/tests";
import { Breadcrumbs, Crumb } from "../components/Breadcrumbs";
import { getRoutePath } from "../utils/portalPages";
import { useCourses } from "../hooks/useCourses";
import { useTests } from "../hooks/useTests";

const reviewRoute = [{ path: routesPath["tests.review"] }];
const retryRoute = [{ path: routesPath["tests.retry"] }];

const getCleanTest = (test: TestI): TestI => {
  const cleanQuestions = test.questions.map((q) => {
    return { ...q, answeredByUser: undefined };
  });

  return { ...test, questions: cleanQuestions };
};

export function Test() {
  const { t } = useTranslation();
  const { courseId, testId } = useParams();
  const location = useLocation();
  const { selectedCourse } = useCourses();
  const checkReviewRoute = matchRoutes(reviewRoute, location);
  const isReview = !!(checkReviewRoute && checkReviewRoute.length > 0);
  const checkRetryRoute = matchRoutes(retryRoute, location);
  const isRetry = !!(checkRetryRoute && checkRetryRoute.length > 0);
  const [test, setTest] = useState<TestI | undefined>();
  const [testTime, setTestTime] = useState<number | undefined>();
  const [initialIndex, setInitialIndex] = useState<number | undefined>();
  const { reviewExam } = useTests();

  const totalErrors = reviewExam?.tests.map((t) => parseInt(`${t.failedQuestions}`)).reduce((a, b) => a + b, 0) ?? 0;
  const mediaErrors = (totalErrors / (reviewExam?.tests.length ?? 1)).toFixed(2);

  const totalLast5Errors =
    reviewExam?.tests
      .slice(-5)
      .map((t) => parseInt(`${t.failedQuestions}`))
      .reduce((a, b) => a + b, 0) ?? 0;
  const mediaLast5Errors = (totalLast5Errors / (reviewExam?.tests.length ?? 1 > 5 ? 5 : reviewExam?.tests.length ?? 1)).toFixed(2);

  useEffect(() => {
    getTestToPerform();
  }, [testId]);

  const getTestToPerform = useCallback(async () => {
    if (!testId || !testId.length) return;
    let newIndex = 0;
    let totalAnswers = 0;
    const response = await performTest(testId);
    const test = isRetry ? getCleanTest(response.test) : response.test;

    if (!isReview) {
      for (let index = 0; index < test.questions.length; index++) {
        if (test.questions[index].answeredByUser) {
          totalAnswers = totalAnswers + 1;
          newIndex = index + 1 < test.questions.length - 1 ? index + 1 : test.questions.length - 1;
        }
      }
    }

    const now = Date.now();

    if (test.timeSeconds > 0 && !isRetry) {
      setTestTime(now + test.timeSeconds * 1000);
    } else {
      const secondsForQuestion = selectedCourse?.secondsForQuestion ?? 60;
      setTestTime(now + (test.totalQuestions - totalAnswers) * secondsForQuestion * 1000);
    }

    setInitialIndex(newIndex);
    setTest(test);
  }, [testId]);

  if (test === undefined || testTime === undefined || initialIndex === undefined) {
    return <LoadingBar text={t("Test.loading-test")} />;
  }

  const getBreadCrumbs = (): Crumb[] => {
    let crumbs: Crumb[] = [{ name: "Inicio", path: getRoutePath("course", { courseId: courseId! }) }];

    const prevPath: string | null = window.localStorage.getItem("test-dashboard");
    if (prevPath) {
      let prevPathMatch = matchPath(routesPath["tests.subject.review"], prevPath) ?? matchPath(routesPath["tests.subject.review.subtopicId"], prevPath);
      if (prevPathMatch) {
        const courseId = prevPathMatch.params.courseId;
        const prevPathTitle: string | null = test.subject;
        crumbs = [
          ...crumbs,
          {
            name: t("Header.Tests.topic"),
            path: getRoutePath("tests.subject", { courseId: courseId! }),
          },
        ];
        crumbs = [...crumbs, { name: prevPathTitle!, path: prevPath }];
      }

      prevPathMatch = matchPath(routesPath["tests.subject"], prevPath);
      if (prevPathMatch) {
        crumbs = [...crumbs, { name: t("Header.Tests.topic")!, path: prevPath }];
      }

      prevPathMatch = matchPath(routesPath["tests.exam"], prevPath);
      if (prevPathMatch) {
        crumbs = [...crumbs, { name: t("Header.Tests.exam")!, path: prevPath }];
      }

      prevPathMatch = matchPath(routesPath["tests.revision"], prevPath);
      if (prevPathMatch) {
        crumbs = [...crumbs, { name: t("Header.Tests.revision"), path: prevPath }];
      }
      prevPathMatch = matchPath(routesPath["tests.review"], prevPath);
      if (prevPathMatch) {
        crumbs = [...crumbs, { name: t("Header.Tests.review"), path: prevPath }];
      }
    }

    crumbs = [...crumbs, { name: `Test ${(reviewExam?.tests?.length ?? 0) + 1} / ${mediaErrors} / ${mediaLast5Errors}` }];

    return crumbs;
  };

  return (
    <>
      {courseId && <Breadcrumbs crumbs={getBreadCrumbs()} />}
      <TestView isReview={isReview} test={test} initialCountDownTime={testTime} initialContent={initialIndex} />
    </>
  );
}
