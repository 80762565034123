import { generateTest, terminateTest as terminateTestAPI } from "../api/tests";
import { CreatedTest } from "../interfaces/tests";

export function useTest() {
  const terminateTest = async (testId: string): Promise<void> => {
    await terminateTestAPI(testId);
  };

  const createTest = async (
    courseId: string,
    testType: "exam" | "subject" | "review" | "failed" | "examReduced",
    failedTestType?: "exam" | "subject" | "review",
    subjectId?: string,
    subtopicId?: string
  ): Promise<CreatedTest> => {
    const response = await generateTest(
      courseId,
      testType,
      failedTestType,
      subjectId,
      subtopicId
    );
    return response.test;
  };

  return {
    terminateTest,
    createTest,
  };
}
