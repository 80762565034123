import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import Spinner from "../../Layout/Spinner";
import { useChat } from "../../../hooks/useChat";
import { selectedCourseState } from "../../../state/global";

export function SendQuestionToChatButton({
  questionId,
}: {
  questionId?: number;
}) {
  const { t } = useTranslation();
  const [isSendingQuestion, setSendingQuestion] = useState<boolean>(false);
  const { sendQuestion } = useChat();

  return (
    <Button
      disabled={!selectedCourseState}
      color="orange"
      onClick={async () => {
        if (!isSendingQuestion) {
          setSendingQuestion(true);
          if (questionId) await sendQuestion(questionId.toString());
          setSendingQuestion(false);
        }
      }}
    >
      <ChatBubbleLeftRightIcon className="w-6 h-6 mr-2 -ml-2" />
      {t("Test.send-to-chat")}
      {isSendingQuestion ? <Spinner className="h-5 w-5 ml-3" /> : null}
    </Button>
  );
}
