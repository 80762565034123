import { Component, ErrorInfo, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { CustomError } from "../utils/CustomError";
import { getRoutePath } from "../utils/portalPages";

interface ErrorBoundaryPropTypes {
  children: ReactNode;
  t: (n: string) => string;
}

class ErrorBoundary extends Component<ErrorBoundaryPropTypes> {
  constructor(props: ErrorBoundaryPropTypes) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error: Error | CustomError, errorInfo: ErrorInfo) {
    if (
      error.message ==
      "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node."
    ) {
      if (process.env.REACT_APP_ENVIRONMENT === "PRO") {
        window.location.href = "https://alumno.torcal.es";
      } else {
        window.location.href = "https://pre-alumno.torcal.es";
      }
    } else {
      this.setState({ errorInfo });
      // eslint-disable-next-line no-console
      console.log(error.message, { errorInfo: JSON.stringify(errorInfo) });
      if (error instanceof CustomError) {
        window.location.href = getRoutePath("login");
      }
    }
  }

  render() {
    const { errorInfo } = this.state as { errorInfo: ErrorInfo };
    const { children } = this.props;

    if (errorInfo) {
      return (
        <div className="flex flex-col h-screen max-w-7xl mx-auto">
          <section className="flex flex-col h-screen justify-center align-middle bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <p className="mb-4 text-2xl tracking-tight font-extrabold lg:text-5xl text-primary-600 ">
                  :(
                </p>
                <h1 className="mb-4 text-2xl tracking-tight font-extrabold lg:text-5xl text-primary-600 ">
                  {this.props.t("ErrorBoundary.something-is-going-wrong")}
                </h1>
                <p className="mb-4 text-md tracking-tight font-bold text-gray-900">
                  {this.props.t("ErrorBoundary.internal-server-error")}
                </p>
                <p className="mb-4 text-lg font-light text-gray-500">
                  {this.props.t("ErrorBoundary.we-are-working-to-fix-it")}
                </p>
              </div>
            </div>
          </section>
        </div>
      );
    }
    return children;
  }
}

export default withTranslation()(ErrorBoundary);
