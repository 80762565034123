import {
  FinishTestResponse,
  TestInfoResponse,
  TestPerformanceResponse,
  TestPerformCreateResponse,
} from "../interfaces/apiResponses";
import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import { TestBySubject, TestReview } from "../interfaces/tests";
import { CustomError } from "../utils/CustomError";
import { apiFetch } from "./api";

export async function getTestsBySubject(
  courseId: string
): Promise<TestBySubject[]> {
  try {
    const subjectsResponse: Record<string, any> = await apiFetch(
      `/test/subjects?courseId=${courseId}`,
      "GET"
    );

    return subjectsResponse.subjects;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function getTestInfo(courseId: string): Promise<TestInfoResponse> {
  return apiFetch(`/test/info?courseId=${courseId}`, "GET");
}

export async function getTestReview(
  courseId: string,
  testType?: "exam" | "subject" | "review" | "failed",
  subjectId?: string,
  subtopicId?: string
): Promise<TestReview> {
  try {
    const params = new URLSearchParams({
      courseId,
    });

    if (testType) {
      params.set("testType", testType);
    }
    if (subjectId) {
      params.set("subjectId", subjectId);
    }
    if (subtopicId) {
      params.set("subtopicId", subtopicId);
    }
    const data: Record<string, any> = await apiFetch(
      `/test/review?${params.toString()}`,
      "GET"
    );

    return data.reviewList;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function generateTest(
  courseId: string,
  testType: "exam" | "subject" | "review" | "failed" | "examReduced",
  failedTestType?: "exam" | "subject" | "review",
  subjectId?: string,
  subtopicId?: string
): Promise<TestPerformCreateResponse> {
  const params = new URLSearchParams({
    courseId,
    testType,
  });
  if (failedTestType) {
    params.set("failedTesttype", failedTestType);
  }
  if (subjectId) {
    params.set("subjectId", subjectId);
  }
  if (subtopicId) {
    params.set("subtopicId", subtopicId);
  }
  return await apiFetch(`/test/perform?${params.toString()}`, "POST");
}

export async function terminateTest(testId: string): Promise<void> {
  return await apiFetch(`/test/incomplete?testId=${testId}`, "DELETE");
}

export async function performTest(
  testId: string
): Promise<TestPerformanceResponse> {
  return await apiFetch(`/test/perform?testId=${testId}`, "GET");
}

export async function sendResponses(
  testId: string,
  timeSeconds: string,
  answers: string
): Promise<void> {
  return await apiFetch(
    `/test/perform/multiple?testId=${testId}&timeSeconds=${timeSeconds}&answers=${answers}`,
    "PUT"
  );
}

export async function finishTest(testId: string): Promise<FinishTestResponse> {
  return await apiFetch(`/test/result?testId=${testId}`, "POST");
}
