import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../api/user";
import { Button } from "../components/Button";
import Spinner from "../components/Layout/Spinner";
import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import { useUser } from "../hooks/useUser";
import { CustomError } from "../utils/CustomError";
import { getRoutePath } from "../utils/portalPages";

export function ForgotPassword() {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>("");
  const [onSuccess, setOnSuccess] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  /**
   * @description if we already have a user, just redirect to the courses page
   */
  useEffect(() => {
    if (user) {
      navigate(getRoutePath("courses"));
    }
  }, [user, navigate]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSuccess("");
    setHasError("");
    setIsLoading(true);
    try {
      await forgotPassword(username);
      setIsLoading(false);
      setOnSuccess(t("ForgotPassword.email-sent"));
    } catch (error) {
      setIsLoading(false);

      const errorMsg =
        error instanceof CustomError
          ? error.getErrorMessage()
          : DEFAULT_ERROR_MESSAGE;

      setHasError(t(errorMsg));
    }
  };

  return (
    <div className="container m-auto">
      <div
        className="w-full h-full left-0 top-0 absolute -z-10 bg-green-torcal bg-cover"
        style={{
          backgroundImage: "url('/loginBackground.jpg')",
        }}
      ></div>
      <div className="h-screen flex flex-col justify-center z-10">
        <div className="w-full xl:w-3/5 lg:w-11/12 flex m-auto">
          <div className="w-full h-auto bg-green-torcal hidden lg:block lg:w-1/2 p-10">
            <div
              className="w-full h-full bg-center bg-no-repeat"
              style={{
                backgroundImage: 'url("/torcalLogoWhite.svg")',
                backgroundColor: "#13A538",
              }}
            ></div>
          </div>
          <div className="w-full lg:w-1/2 bg-white p-5 pt-16 pb-14">
            <h3 className="text-lg text-center">
              {t("ForgotPassword.forgot-password-desc")}
            </h3>
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded space-y-3"
              onSubmit={onSubmit}
            >
              <div>
                <label className="block mb-2 text-lg" htmlFor="email">
                  {t("Login.user")}
                </label>
                <input
                  required
                  name="email"
                  id="email"
                  type="text"
                  className="bg-white w-full py-3 px-2 text-md leading-tight text-gray-light border border-gray-light rounded focus:outline-none focus:shadow-outline"
                  placeholder="Usuario"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUsername(event.target.value);
                  }}
                />
              </div>
              {onSuccess.length === 0 ? (
                <div>
                  <Button disabled={isLoading} type="submit" className="w-full">
                    {isLoading && <Spinner />}
                    {t("ForgotPassword.send")}
                  </Button>
                </div>
              ) : null}

              {hasError.length > 0 ? (
                <p className="text-red-500 font-light text-xs text-right">
                  {hasError}
                </p>
              ) : null}

              {onSuccess.length > 0 ? (
                <p className="text-green-torcal font-light text-xs text-right">
                  {onSuccess}
                </p>
              ) : null}

              <Link
                to={getRoutePath("login")}
                className="text-sm text-gray-medium align-baseline hover:text-gray-dark right-0 font-bold"
              >
                {t("ForgotPassword.sign-in")}
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
