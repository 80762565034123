import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { getContent, markAsViewed } from "../api/lessons";
import { LessonContent, LessonContentContent } from "../interfaces/courses";
import { useUser } from "./useUser";

interface hookProps {
  isLoadingLessonContent: boolean;
  error?: string;
  lessonContent?: LessonContent;
  selectedContent?: LessonContentContent;
}

export const hookState = atom<hookProps>({
  key: "lessonHookState",
  default: {
    isLoadingLessonContent: false,
  },
});

export function useLesson() {
  const { lessonId } = useParams();
  const [state, setState] = useRecoilState(hookState);
  const { setUserMetadata } = useUser();

  const reset = () => {
    setState((prev) => {
      return {
        ...prev,
        lessonContent: undefined,
        selectedContent: undefined,
      };
    });
  };

  const setSelectedContent = async (content: LessonContentContent) => {
    setUserMetadata({ currentLesson: { slide: content.question, lessonDetails: content }, currentTest: null });
    if (!state.lessonContent) return;
    if (!content.viewed) {
      const viewedContent = { ...content, viewed: true };
      markAsViewed(content);
      const newContents = state.lessonContent?.content.map((c) => (c.id === content.id ? viewedContent : c));

      const newLesson = {
        ...state.lessonContent,
        contentsDone: (state.lessonContent?.contentsDone ?? 0) + 1,
        content: newContents,
      };
      setState((prev) => {
        return {
          ...prev,
          lessonContent: newLesson,
          selectedContent: viewedContent,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          selectedContent: content,
        };
      });
    }
  };

  const answerQuestion = (content: LessonContentContent, answerId: number) => {
    if (!state.lessonContent) return;
    markAsViewed(content, answerId.toString());
    const newContent = { ...content, viewed: true, answeredByUser: answerId };
    const newContents = state.lessonContent?.content.map((c) => (c.id === newContent.id ? newContent : c));

    const newLesson = {
      ...state.lessonContent,
      contentsDone: (state.lessonContent?.contentsDone ?? 0) + 1,
      content: newContents,
    };
    setState((prev) => {
      return {
        ...prev,
        lessonContent: newLesson,
        selectedContent: newContent,
      };
    });
  };

  const getLessonContent = useCallback(
    async (lessonId: string) => {
      try {
        const newLesson = await getContent(lessonId);
        const currentContent = state.lessonContent?.content.find((c) => !c.viewed);
        setState((prev) => {
          return {
            ...prev,
            lessonContent: newLesson,
            selectedContent: currentContent ?? newLesson?.content[0],
            isLoadingLessonContent: false,
          };
        });
      } catch (error) {}
    },
    [lessonId]
  );

  useEffect(() => {
    if (!state.isLoadingLessonContent && lessonId && lessonId !== state.lessonContent?.id.toString()) {
      setState((prev) => {
        return {
          ...prev,
          isLoadingLessonContent: true,
          lessonContent: undefined,
          selectedContent: undefined,
        };
      });
      getLessonContent(lessonId);
    }
  }, [lessonId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return {
    reset,
    answerQuestion,
    selectedContent: state.selectedContent,
    setSelectedContent,
    lessonContent: state.lessonContent,
    isLoadingLessonContent: state.isLoadingLessonContent,
  };
}
