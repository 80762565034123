import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { useClassroom } from "../../hooks/useClassroom";
import { meetingState } from "../../state/meeting";
import Loading from "../Layout/Loading";
import { OnlineMeeting } from "./MeetingView/OnlineMeeting";
import { VideoWrapper } from "./MeetingView/VideoWrapper";
import { useLocation } from "react-router-dom";

export function KeepMeeting() {
  const { t } = useTranslation();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const { isLoadingLesson, selectedLesson } = useClassroom();
  const location = useLocation();

  useEffect(() => {
    if (!selectedLesson) return;
    setMeeting({ ...meeting, session: selectedLesson });
  }, [selectedLesson]);

  if (typeof meeting != "undefined") {
    if (isLoadingLesson && !meeting?.session) {
      return (
        <div className="fixed left-0 right-0 top-24 bottom-0">
          <Loading text={t("Meeting.loading-meeting")} />
        </div>
      );
    }

    const modeComplete =
      location.pathname.includes("cursos/") &&
      location.pathname.includes("clase/");

    let classNameModeComplete = modeComplete
      ? "fixed left-0 right-0 top-24 bottom-0"
      : "";
    return (
      <div className={classNameModeComplete}>
        {meeting?.session?.type === "online" ? (
          <OnlineMeeting modeComplete={modeComplete} />
        ) : (
          <VideoWrapper />
        )}
      </div>
    );
  } else {
    return <></>;
  }
}
