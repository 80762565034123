import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTests } from "../../hooks/useTests";
import { Answer } from "../../interfaces/courses";
import { Test, TestResult } from "../../interfaces/tests";
import { ContentQuestion } from "../ContentView/ContentQuestion";
import { ControlButtons } from "./TestView/ControlButtons";
import { FinishModal } from "./TestView/FinishModal";
import { PaginationButtons } from "./TestView/PaginationButtons";
import { TopBar as TopBarReview } from "./TestView/Review/TopBar";
import { TopBar } from "./TestView/TopBar";
import { getPreffixFirebase } from "../../api/api";
import { ref, set, get, child, remove, update } from "firebase/database";
import { getFirebaseDatabase } from "../../utils/firebase";
import { useUser } from "../../hooks/useUser";
import { useRecoilState } from "recoil";
import { meetingState } from "../../state/meeting";

const INTERVAL_SAVE = 180; //seconds

export function TestView({ test, isReview, initialContent, initialCountDownTime }: { test: Test; isReview: boolean; initialContent: number; initialCountDownTime: number }) {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState<string>(t("Test.sure-about-finish-test"));
  const { saveAnswers, finishTest } = useTests();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isFinishingTest, setIsFinishingTest] = useState<boolean>(false);
  const [isFinishingError, setIsFinishingError] = useState<boolean>(false);
  const [testSummary, setTestSummary] = useState<TestResult>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(initialContent);

  const { user, setUserMetadata, setUserMetadataCurrentQuestion } = useUser();
  const [meeting, setMeeting] = useRecoilState(meetingState);
  const sessionId = meeting?.session?.sessionId;
  const sessionType = meeting?.session?.type;

  useEffect(() => {
    const intervalId = setInterval(() => {
      saveAnswers({ ...test, questions: test.questions }, timeLeft.toString());
    }, INTERVAL_SAVE * 1000);

    return () => {
      saveAnswers({ ...test, questions: test.questions }, timeLeft.toString());
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setUserMetadata({ currentLesson: null, currentTest: { testDetails: test } });
  }, [test]);

  useEffect(() => {
    setUserMetadataCurrentQuestion(test.questions[currentQuestionIndex]?.question);
  }, [currentQuestionIndex]);

  const finishTestAction = async () => {
    if (!test) return;
    setIsFinishingTest(true);

    try {
      setTestSummary(await finishTest(test, timeLeft.toString()));
      setLastTestFinished(test);
      clearAnswerFirebaseRealtime();
    } catch (error) {
      setIsFinishingError(true);
    } finally {
      setIsFinishingTest(false);
    }
  };

  const clearAnswerFirebaseRealtime = () => {
    // If not sessionId or not online session, return
    if (sessionId === undefined || sessionType == undefined || sessionType != "online") return;

    const participantId = user?.username?.replace(/./g, "_POINT_");
    const doingTestPrefix = "doingTest" + getPreffixFirebase() + "/session" + sessionId + "/" + participantId;

    remove(ref(getFirebaseDatabase(), doingTestPrefix));
  };

  const setLastTestFinished = (test: Test) => {
    const participantId = user?.username?.replace(/\./g, "_POINT_");
    const lastTestFinishedDBPath = `lastTestFinished${getPreffixFirebase()}/${participantId}`;
    set(ref(getFirebaseDatabase(), lastTestFinishedDBPath), test);
  };

  const onSetCurrentQuestionIndex = (index: number) => {
    const participantId = user?.username?.replace(/\./g, "_POINT_");
    const doingTestPrefix = `doingTest${getPreffixFirebase()}/session${sessionId}/${participantId}`;

    update(ref(getFirebaseDatabase(), doingTestPrefix), { currentQuestionId: test.questions[index]?.id });
    setCurrentQuestionIndex(index);
  };
  const saveAnswerFirebaseRealtime = (test: any, questionId: number, answer: any) => {
    // If not sessionId or not online session, return
    if (sessionId === undefined || sessionType == undefined || sessionType != "online") return;

    // Save basic information about test
    const participantId = user?.username?.replace(/\./g, "_POINT_");
    const doingTestPrefix = `doingTest${getPreffixFirebase()}/session${sessionId}/${participantId}`;
    const saveQuestionPreffix = `${doingTestPrefix}/questions/${questionId}`;

    get(child(ref(getFirebaseDatabase()), doingTestPrefix))
      .then((snapshot) => {
        // If exists and test is equal
        if (snapshot.exists() && snapshot.child("test").exportVal() == test.id) {
          // nothing
        } else {
          // first time => it will create the basic information
          set(ref(getFirebaseDatabase(), doingTestPrefix), {
            test: test.id,
            begin: test.startTime,
            total: test.questions.length,
          });
        }
        set(ref(getFirebaseDatabase(), saveQuestionPreffix), {
          answer: answer.id,
          correct: answer.correct,
        });
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const saveAnswer = (contentId: number, answer?: Answer) => {
    if (isReview) return;
    const newQuestionsList = test?.questions.map((content) => {
      if (content.id === contentId) {
        saveAnswerFirebaseRealtime(test, contentId, answer);
        return {
          ...content,
          answeredByUser: answer ? answer.id : undefined,
        };
      }
      return content;
    });
    if (newQuestionsList && test) {
      test.questions = newQuestionsList;
    }
  };

  const onHaveDoubt = () => {
    const question = test.questions[currentQuestionIndex];
    question.haveDoubt = !question.haveDoubt;
    if (question.haveDoubt && currentQuestionIndex + 1 < test.questions.length) onSetCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  return (
    <>
      <div className="w-full flex flex-col justify-start space-y-8">
        {isReview ? (
          <TopBarReview questionId={test.questions[currentQuestionIndex] ? test.questions[currentQuestionIndex].id : undefined} />
        ) : (
          <TopBar
            initialTime={initialCountDownTime}
            isPauseCountDown={showModal}
            updateLeftTime={(secondsLeft) => {
              setTimeLeft(secondsLeft);
            }}
            questionId={test.questions[currentQuestionIndex] ? test.questions[currentQuestionIndex].id : undefined}
            sessionType={sessionType}
          />
        )}
        <div className="bg-white flex flex-col justify-start w-full p-6 shadow-lg">
          <div className="pb-8">
            {test.questions[currentQuestionIndex] ? (
              <ContentQuestion
                isReview={isReview}
                showAnswer={isReview}
                content={test.questions[currentQuestionIndex]}
                saveAnswer={saveAnswer}
                numberOfQuestion={currentQuestionIndex + 1}
              />
            ) : null}
          </div>
          <ControlButtons
            isReview={isReview}
            currentValue={currentQuestionIndex}
            updateValue={onSetCurrentQuestionIndex}
            maxValue={test.questions.length}
            onHaveDoubt={onHaveDoubt}
            haveDoubt={test.questions[currentQuestionIndex]?.haveDoubt}
            onFinishTest={() => setShowModal(true)}
          />
        </div>

        <div className="w-full mt-6 flex justify-aroun flex-wrap">
          <PaginationButtons isReview={isReview} currentIndex={currentQuestionIndex} setCurrentIndex={onSetCurrentQuestionIndex} questions={test.questions} />
        </div>
      </div>

      {isReview ? null : (
        <FinishModal
          title={modalTitle}
          finishTest={finishTestAction}
          isFinishingTest={isFinishingTest}
          setShowModal={setShowModal}
          showModal={showModal}
          showError={isFinishingError}
          summary={testSummary}
        />
      )}
    </>
  );
}
