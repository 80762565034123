import { ReactNode } from "react";

function Head({ children }: { children: ReactNode }) {
  return <thead className="text-sm bg-gray-50">{children}</thead>;
}

function Body({ children }: { children: ReactNode }) {
  return <tbody>{children}</tbody>;
}

function Foot({ children }: { children: ReactNode }) {
  return <tfoot>{children}</tfoot>;
}

function TableRoot({ children }: { children: ReactNode }) {
  return (
    <div className="overflow-x-auto relative p-6 bg-white">
      <table className="w-full text-sm text-left text-gray-500">
        {children}
      </table>
    </div>
  );
}

export const Table = Object.assign(TableRoot, { Head, Body, Foot });
