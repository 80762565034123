import { CheckCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { TestBySubject } from "../../interfaces/tests";
import { getRoutePath } from "../../utils/portalPages";

export function SubTopicCards({
  subtopics,
  activeSubtopic,
  onSelect,
}: {
  subtopics: TestBySubject[];
  activeSubtopic?: string;
  onSelect: (value: TestBySubject) => void;
}) {
  const { courseId, subjectId } = useParams();

  return (
    <>
      {courseId &&
        subjectId &&
        subtopics.map((st, index) => (
          <Link
            to={getRoutePath("tests.subject.review.subtopicId", {
              courseId,
              subjectId,
              subtopicId: st.id.toString(),
            })}
            key={st.id}
            className={classNames(
              "w-full bg-gray-50 flex align-middle p-4 space-y-3 justify-end border-r-[10px] border-b-gray-medium hover:bg-gray-200",
              st.id.toString() === activeSubtopic ||
                (!activeSubtopic && index === 0)
                ? "border-green-torcal"
                : "border-transparent"
            )}
            onClick={() => onSelect(st)}
          >
            <p className="flex grow flex-col justify-start text-sm">
              <span className="text-left truncate w-[300px]">{st.title}</span>
            </p>
            <CheckCircleIcon
              className={classNames(
                "shrink-0 h-8 w-8",
                st.testsDone === st.maxTest
                  ? "fill-green-torcal"
                  : "fill-gray-400"
              )}
            />
          </Link>
        ))}
    </>
  );
}
