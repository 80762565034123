import { ReactNode } from "react";

export function SubjectReviewWrapper({
  children,
  splitView,
}: {
  splitView: boolean;
  children: ReactNode;
}) {
  if (splitView) {
    return (
      <div className="w-full relative flex justify-start flex-1">
        <div className="absolute h-full max-h-full flex justify-start pb-0 w-full">
          {children}
        </div>
      </div>
    );
  }

  return <div className="flex flex-col space-y-5">{children}</div>;
}
