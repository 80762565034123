import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { getClassrooms as getClassroomsAPI, getSession } from "../api/courses";
import { ClassRoom, SessionTabs } from "../interfaces/courses";
import { selectedSessionState } from "../state/global";

export function useClassroom() {
  const { lessonId } = useParams();
  const [error, setError] = useState<string | undefined>();
  const [isLoadingClassroom, setIsLoadingClassroom] = useState<boolean>(true);
  const [isLoadingLesson, setIsLoadingLesson] = useState<boolean>(true);
  const [classrooms, setClassrooms] = useState<ClassRoom[] | undefined>();
  const [sessionTabs, setSessionTabs] = useState<SessionTabs[]>([]);
  const [selectedClassroom, setSelectedClassroom] = useState<
    ClassRoom | undefined
  >();
  const [selectedLesson, setSelectedLesson] =
    useRecoilState(selectedSessionState);

  const resetSelectedSessionState = useResetRecoilState(selectedSessionState);

  const getLesson = useCallback(async () => {
    setIsLoadingLesson(true);
    setError(undefined);
    try {
      if (
        (!selectedLesson && lessonId && sessionTabs) ||
        (lessonId && selectedLesson?.sessionId.toString() !== lessonId)
      ) {
        const sessionResponse = await getSession(parseInt(lessonId, 10));
        setSelectedLesson(sessionResponse.session);
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoadingLesson(false);
    }
  }, [lessonId, sessionTabs, selectedLesson]);

  useEffect(() => {
    getLesson();
  }, [lessonId, sessionTabs, selectedLesson]);

  const getClassrooms = useCallback(async (): Promise<void> => {
    setIsLoadingClassroom(true);
    setError(undefined);
    try {
      const screen = await getClassroomsAPI();
      setClassrooms(screen.classrooms);
      if (!selectedClassroom && screen.classrooms.length > 0) {
        setSelectedClassroom(screen.classrooms[0]);
        setSessionTabs(screen.sessionTabs);
      }
      setIsLoadingClassroom(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoadingClassroom(false);
    }
  }, []);

  useEffect(() => {
    getClassrooms();
  }, []);

  const getSessionTabs = (classroom: ClassRoom): SessionTabs[] => {
    if (!sessionTabs) return [];
    const sessionsByClassroom = sessionTabs.map((x) => {
      return {
        ...x,
        sessions: x.sessions.filter((f) => f.classroomId === classroom.id),
      };
    });

    return sessionsByClassroom;
  };

  return {
    resetSelectedSessionState,
    isLoadingClassroom,
    error,
    classrooms,
    selectedClassroom,
    setSelectedClassroom,
    getSessionTabs,
    selectedLesson,
    isLoadingLesson,
  };
}
