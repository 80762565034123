import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VideoRoom } from "./VideoCall/VideoRoom";

interface VideoConfig {
  hasVideo: boolean;
  token?: string;
  roomName?: string;
}

export default function BubbleVideoCall({
  videoConfig,
}: {
  videoConfig?: VideoConfig;
}) {
  const { t } = useTranslation();
  const [showVideoCall, setShowVideoCall] = useState<boolean>(false);

  if (!videoConfig) {
    return null;
  }

  return (
    <>
      {videoConfig.hasVideo &&
      videoConfig.roomName &&
      videoConfig.token &&
      showVideoCall ? (
        <VideoRoom
          onCloseVideo={() => {
            setShowVideoCall(false);
          }}
          roomName={videoConfig.roomName}
          token={videoConfig.token}
        />
      ) : null}

      {videoConfig.hasVideo ? (
        <button
          onClick={() => {
            setShowVideoCall(true);
          }}
          className="relative bg-gray-100 flex flex-col w-full p-2 rounded-xl"
        >
          <p className="text-left text-xs font-bold">
            {t("Chat.callIncoming")}
          </p>
        </button>
      ) : null}
    </>
  );
}
