import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { finishTest as finishTestAPI, sendResponses } from "../api/tests";
import { Test, TestResult } from "../interfaces/tests";
import { testBySubjectState, testInfoState, testReviewState } from "../state/test";

export function useTests() {
  const { courseId, subjectId, subtopicId } = useParams();
  const testInfoLoadable = useRecoilValueLoadable(testInfoState(courseId));
  const testReviewLoadable = useRecoilValueLoadable(testReviewState({ courseId, subjectId, subtopicId }));
  const testBySubjectLoadable = useRecoilValueLoadable(testBySubjectState(courseId));
  const refreshTestBySubject = useRecoilRefresher_UNSTABLE(testBySubjectState(courseId));
  const refreshInfo = useRecoilRefresher_UNSTABLE(testInfoState(courseId));
  const refreshReview = useRecoilRefresher_UNSTABLE(testReviewState({ courseId, subjectId, subtopicId }));

  const refreshTestInfo = () => {
    refreshInfo();
    refreshReview();
    refreshTestBySubject();
  };

  const saveAnswers = async (test: Test, timeLeft: string): Promise<void> => {
    const userAnswers = [];
    for (const question of test.questions) {
      if (question.answeredByUser) {
        userAnswers.push(question.answeredByUser.toString());
      } else {
        userAnswers.push("");
      }
    }

    if (userAnswers.length > 0) {
      await sendResponses(test.id, timeLeft, userAnswers.toString());
    }
  };

  const finishTest = async (test: Test, timeLeft: string): Promise<TestResult> => {
    await saveAnswers(test, timeLeft);
    const response = await finishTestAPI(test.id);
    return response.result;
  };

  const infoExam =
    testInfoLoadable.state === "hasValue" && testInfoLoadable.contents?.infoTests && testInfoLoadable.contents?.infoTests?.length > 0
      ? testInfoLoadable.contents?.infoTests.find((t) => t.type === "exam")
      : undefined;

  const infoReview =
    testInfoLoadable.state === "hasValue" && testInfoLoadable.contents?.infoTests && testInfoLoadable.contents?.infoTests?.length > 0
      ? testInfoLoadable.contents?.infoTests.find((t) => t.type === "review")
      : undefined;

  const infoSubject =
    testInfoLoadable.state === "hasValue" && testInfoLoadable.contents?.infoTests && testInfoLoadable.contents?.infoTests?.length > 0
      ? testInfoLoadable.contents?.infoTests.find((t) => t.type === "subject")
      : undefined;

  const reviewExam =
    testReviewLoadable.state === "hasValue" && testReviewLoadable.contents?.testCategories ? testReviewLoadable.contents.testCategories.find((t) => t.type === "exam") : undefined;

  const reviewReview =
    testReviewLoadable.state === "hasValue" && testReviewLoadable.contents?.testCategories
      ? testReviewLoadable.contents.testCategories.find((t) => t.type === "review")
      : undefined;

  return {
    isReviewTestEnabled: !!infoReview,
    finishTest,
    saveAnswers,
    refreshTestInfo,
    refreshReview,
    isLoadingTestBySubject: testBySubjectLoadable.state === "loading",
    testBySubject: testBySubjectLoadable.state === "hasValue" ? testBySubjectLoadable.contents : undefined,
    testExam: testInfoLoadable.state === "hasValue" ? testInfoLoadable.contents : undefined,
    errorTestBySubject: testBySubjectLoadable.state === "hasError" ? testBySubjectLoadable.contents : undefined,
    infoExam,
    infoSubject,
    infoReview,
    reviewExam,
    reviewReview,
    isLoadingTestInfo: testInfoLoadable.state === "loading",
    courseProgress: testInfoLoadable.state === "hasValue" ? testInfoLoadable.contents?.porcentajeDoneLessons : undefined,
    errorTestInfo: testInfoLoadable.state === "hasError" ? testInfoLoadable.contents : undefined,
    isLoadingTestReview: testReviewLoadable.state === "loading",
    testReview: testReviewLoadable.state === "hasValue" ? testReviewLoadable.contents : undefined,
    errorTestReview: testReviewLoadable.state === "hasError" ? testReviewLoadable.contents : undefined,
  };
}
