import axios from "axios";
import { User } from "../interfaces/user";
import { USER_SESSION_STORAGE_KEY } from "../utils/config";
import { saveUserInfo } from "../utils/sessionStorage";

const getToken = () => {
  const userData = sessionStorage.getItem(USER_SESSION_STORAGE_KEY);
  if (userData) {
    const user = JSON.parse(userData);
    return user.token;
  }
};

const getRefreshToken = () => {
  const userData = sessionStorage.getItem(USER_SESSION_STORAGE_KEY);
  if (userData) {
    const user = JSON.parse(userData);
    return user.refreshToken;
  }
};

export async function apiFetch<Type>(
  path: string,
  method: string,
  headers: Record<string, unknown> = {},
  body?: Record<string, unknown>
): Promise<Type> {
  const { data } = await axiosInstance({
    url: path,
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      ...headers,
    },
    data: body ? JSON.stringify(body) : null,
  });
  return data;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  },
});

export async function reTry(originalRequest: any): Promise<Response> {
  const userInfo = await refreshToken();
  const { data } = await axiosInstance({
    url: originalRequest.url,
    method: originalRequest.method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
    data: originalRequest.body ? JSON.stringify(originalRequest.body) : null,
  });
  return data;
}

/**  Add a response interceptor */
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    /** Any status codes that falls outside the range of 2xx cause this function to trigger */
    const originalRequest = error.config;
    const isValidStatusCode =
      error.response.status === 401 || error.response.status === 400;

    if (isValidStatusCode && originalRequest.url !== "/auth/refresh") {
      return await reTry(originalRequest);
    }
    return Promise.reject(error);
  }
);

export async function refreshToken(): Promise<User> {
  const refreshToken = getRefreshToken();
  const { data } = await axios({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    url: "/auth/refresh",
    method: "POST",
    headers: { accept: "application/json" },
    data: { refreshToken },
  });
  saveUserInfo(data);
  return data;
}

export const getPreffixFirebase = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "PRO") {
    return "";
  } else {
    return process.env.REACT_APP_ENVIRONMENT;
  }
};
