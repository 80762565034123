import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "../components/Breadcrumbs";
import LoadingBar from "../components/Layout/LoadingBar";
import { ContentWrapper } from "../components/Lesson/ContentWrapper";
import { SubmenuBar } from "../components/Lesson/SubmenuBar";
import { useLesson } from "../hooks/useLesson";
import { getLessonPercentage } from "../utils/Percentage";
import { getRoutePath } from "../utils/portalPages";
import { useEffect } from "react";
import { useUser } from "../hooks/useUser";

export function Lesson() {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { isLoadingLessonContent, lessonContent } = useLesson();
  const { setUserMetadata } = useUser();

  useEffect(() => {
    setUserMetadata({ currentPage: `Leccion ${lessonContent?.title}` });
  }, [lessonContent]);

  if (isLoadingLessonContent) {
    return <LoadingBar text={"Cargando contenido ..."} />;
  }

  if (!lessonContent) return null;

  const percentage = getLessonPercentage(lessonContent);

  return (
    <>
      {courseId && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", { courseId: courseId! }),
            },
            {
              name: t("Header.lessons"),
              path: getRoutePath("lessons", { courseId: courseId! }),
            },
            { name: lessonContent.title },
          ]}
        />
      )}

      <div className="w-full relative flex justify-start flex-1">
        <div className="absolute h-full max-h-full overflow-hidden flex justify-start pb-0 w-full">
          {lessonContent?.content.length === 0 ? (
            <p className="text-lg font-light mb-8">{t("Lessons.no-lessons-available")}</p>
          ) : (
            <>
              <div className="flex flex-col w-96 overflow-scroll">
                <div className="flex flex-col bg-white p-4 space-y-3">
                  <p className="flex text-sm">
                    <Link
                      to={getRoutePath("lessons", {
                        courseId: courseId ?? "",
                      })}
                      className="rounded-full p-2 hover:bg-gray-200 mr-2"
                    >
                      <ChevronLeftIcon className="h-8 w-8" />
                    </Link>
                    <span className="self-center">{lessonContent.title}</span>
                  </p>
                  <div className="w-full text-center mt-auto">
                    <div className="overflow-hidden h-5 text-xs flex bg-gray-200 rounded-full w-full">
                      <div
                        style={{
                          width: `${percentage}%`,
                        }}
                        className={classNames(
                          "shadow-none flex flex-col text-center whitespace-nowrap justify-center",
                          percentage === 100 ? "bg-green-torcal/70" : "bg-orange-torcal/70"
                        )}
                      >
                        {percentage} %
                      </div>
                    </div>
                  </div>
                </div>
                <SubmenuBar />
              </div>
              <ContentWrapper />
            </>
          )}
        </div>
      </div>
    </>
  );
}
