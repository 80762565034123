import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

export default function Loading({ text = "loading" }: { text?: string }) {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-center">
      <Spinner lineColor="text-gray-torcal" className="h-10 ml-0" />
      <p className="text-md text-center text-gray-torcal font-light mt-6">
        {t(text)}
      </p>
    </div>
  );
}
