import { ReactNode, Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { getRoutePath } from "../../utils/portalPages";
import Layout from "./Layout";
import Loading from "./Loading";

/**
 * @description This wrapper will be in charge of detect if the user has a
 *  valid session in the application and has access to the requested page
 */
export default function PageWrapper({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const loginPath = getRoutePath("login");
  const coursesPath = getRoutePath("courses");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(loginPath);
    }
    if (location.pathname === loginPath && isAuthenticated) {
      navigate(coursesPath);
    }
  }, [isAuthenticated]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-col h-screen justify-center align-middle w-full">
          <Loading />
        </div>
      }
    >
      <Layout>{children}</Layout>
    </Suspense>
  );
}
