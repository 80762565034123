import { ClockIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import Countdown, { CountdownTimeDelta } from "react-countdown";
import { useTranslation } from "react-i18next";
import { ToolBar } from "../../Layout/ToolBar";
import { ZoomButtons } from "./ZoomButtons";
import { SendQuestionToChatButton } from "./SendQuestionToChatButton";

export function TopBar({
  isPauseCountDown,
  initialTime,
  updateLeftTime,
  questionId,
  sessionType,
}: {
  isPauseCountDown: boolean;
  initialTime: number;
  updateLeftTime: (a: any) => void;
  questionId?: number;
  sessionType: "online" | "offline" | undefined;
}) {
  const { t } = useTranslation();
  const countdownRef = useRef<Countdown>();

  useEffect(() => {
    if (isPauseCountDown && countdownRef.current) {
      countdownRef.current.api?.pause();
    }
    if (!isPauseCountDown && countdownRef.current) {
      countdownRef.current.api?.start();
    }
  }, [isPauseCountDown]);

  const onTick = (data: CountdownTimeDelta) => {
    updateLeftTime(data.total / 1000);
  };

  const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

  return (
    <ToolBar>
      <ZoomButtons />

      <div className="grow mr-10 flex justify-end space-x-3 align-bottom">
        <Countdown
          // @ts-ignore
          ref={countdownRef}
          renderer={(props) => (
            <div style={{ display: "flex" }}>
              <ClockIcon className={`h-6 w-6 ${props.completed ? "fill-red-600" : "fill-green-600"}`} />
              <span className={`text-${props.completed ? "red-600" : "green-600"}`} style={{ width: 75 }}>
                {props.completed ? "-" : ""}
                {zeroPad(props.hours, 2)}:{zeroPad(props.minutes, 2)}:{zeroPad(props.seconds, 2)}
              </span>
            </div>
          )}
          date={initialTime}
          overtime={true}
          onTick={onTick}
        />
        <span className="text-xs leading-6">{t("Test.available-time")}</span>
      </div>

      {sessionType !== "online" ? <SendQuestionToChatButton questionId={questionId}></SendQuestionToChatButton> : null}
    </ToolBar>
  );
}
