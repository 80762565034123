export const DEFAULT_ERROR_MESSAGE =
  "Ups, algo salio mal, inténtelo nuevamente mas tarde.";
export const BAD_CREDENTIALS = "Usuario o password incorrectos.";
export const LOGIN_ERROR = "Ups, algo salió mal al hacer el login del usuario.";
export const LOGOUT_ERROR =
  "Ups, algo salió mal al cerrar la sesión del usuario.";
export const REFRESH_TOKEN_ERROR =
  "Se obtuvo un error al refrescar el token del usuario";
export const SEND_MAIL_ERROR =
  "No pudimos enviar el correo debido a un error interno, intente nuevamente";
