import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteName } from "../../interfaces/portalPages";
import { TestReviewCategory } from "../../interfaces/tests";
import { getRoutePath } from "../../utils/portalPages";
import { Button } from "../Button";
import { Table } from "../Table/Table";

export function ExistentTestTable({
  reviewCategory,
  isLoading = false,
  tooltipText,
}: {
  tooltipText?: string;
  reviewCategory?: TestReviewCategory;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = useParams();
  const isEmpty =
    !isLoading && (!reviewCategory || reviewCategory.tests.length === 0);

  const onClick = (routeName: RouteName, testId: string) => {
    if (!courseId) return;
    window.localStorage.setItem("test-dashboard", location.pathname);
    navigate(
      getRoutePath(routeName, {
        courseId,
        testId,
      })
    );
  };

  return (
    <Table>
      <Table.Head>
        <tr>
          <th scope="col" className="py-3 px-6 w-5"></th>
          <th scope="col" className="py-3 px-6 w-7"></th>
          <th scope="col" className="py-3 px-6 font-normal text-center">
            {t("Test.date")}
          </th>
          <th scope="col" className="py-3 px-6 font-normal text-center">
            {t("Test.hour")}
          </th>
          <th scope="col" className="py-3 px-6 font-normal text-center">
            {t("Test.total-questions")}
          </th>
          <th scope="col" className="py-3 px-6 font-normal text-center">
            {t("Test.errors")}
          </th>
          <th
            scope="col"
            className="py-3 px-6 font-normal text-center w-44"
          ></th>
        </tr>
      </Table.Head>
      <Table.Body>
        {isLoading ? <LoadingBlock /> : null}
        {isEmpty ? (
          <EmptyBlock />
        ) : (
          reviewCategory?.tests.map((test, index) => (
            <tr key={test.id} className="bg-white border-b hover:bg-gray-50">
              <th scope="row" className="text-center">
                {index + 1}
              </th>
              <td className="py-4 px-6 text-center font-medium whitespace-nowrap">
                {test.face === "sad" ? (
                  <XMarkIcon className="h-6 w-6 fill-red-600" />
                ) : (
                  <CheckIcon className="h-6 w-6 fill-green-torcal" />
                )}
              </td>
              <td className="py-4 px-6 text-center">{test.date}</td>
              <td className="py-4 px-6 text-center">{test.time}</td>
              <td className="py-4 px-6 text-center">{test.totalQuestions}</td>
              <td className="py-4 px-6 text-center">{test.failedQuestions}</td>
              <td className="py-4 px-6 text-center flex space-x-2">
                {courseId ? (
                  <Button
                    onClick={() => {
                      onClick("tests.review", test.id.toString());
                    }}
                    className={"text-xs relative py-1 px-2"}
                    color="orange"
                  >
                    {t("Test.review")}
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    onClick("tests.retry", test.id.toString());
                  }}
                  disabled={!test.repeatTest}
                  className={"text-xs relative group py-1 px-2"}
                >
                  <>
                    {t("Test.repeat")}
                    {!test.repeatTest &&
                    tooltipText &&
                    tooltipText?.length > 0 ? (
                      <div className="absolute -left-[300px] -top-[20px] items-center hidden mb-6 group-hover:flex flex-grow">
                        <span className="w-72 relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                          {tooltipText}
                        </span>
                        <div className="absolute left-[282px] w-3 h-3 -mr-10 rotate-45 bg-black"></div>
                      </div>
                    ) : null}
                  </>
                </Button>
              </td>
            </tr>
          ))
        )}
      </Table.Body>
    </Table>
  );
}

function LoadingBlock() {
  const { t } = useTranslation();
  return (
    <tr key="empty" className="bg-white hover:bg-gray-50 h-[70px]">
      <th
        scope="row"
        colSpan={7}
        className="py-4 px-6 text-center whitespace-nowrap"
      >
        {t("Test.loading")}
      </th>
    </tr>
  );
}

function EmptyBlock() {
  const { t } = useTranslation();
  return (
    <tr key="empty" className="bg-white hover:bg-gray-50 h-[70px]">
      <th
        scope="row"
        colSpan={7}
        className="py-4 px-6 text-center whitespace-nowrap"
      >
        {t("Test.no-tests")}
      </th>
    </tr>
  );
}
