import { useParticipant } from "@videosdk.live/react-sdk";
import { useEffect, useRef } from "react";

interface ParticipantAudioPlayerProps {
  participantId: string;
  participantKey: string;
}

export const ParticipantAudioPlayer = ({ participantId, participantKey }: ParticipantAudioPlayerProps) => {
  const { micOn, micStream, isLocal, consumeMicStreams, stopConsumingMicStreams } = useParticipant(participantId);
  const audioPlayer = useRef<any>();

  useEffect(() => {
    if (!isLocal) {
      consumeMicStreams();
      return () => {
        stopConsumingMicStreams();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLocal && audioPlayer.current && micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      audioPlayer.current.srcObject = mediaStream;
      /*
        audioPlayer.current.play().catch((err) => {
          if (
            err.message ===
            "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"
          ) {
            console.error("audio" + err.message);
          }
        });
        */
    } else {
      audioPlayer.current.srcObject = null;
    }
  }, [micStream, micOn, isLocal, participantId]);

  return <audio key={participantKey} autoPlay playsInline controls={false} ref={audioPlayer} />;
};
