export class CustomError extends Error {
  isCustom = true;
  errorCode: number | undefined;

  constructor(message: string, errorCode?: number) {
    super(message);
    this.errorCode = errorCode;
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  getErrorMessage() {
    return this.message;
  }
  getErrorCode() {
    return this.errorCode;
  }
}
