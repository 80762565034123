import { atom } from "recoil";
import { Meeting } from "../interfaces/meeting";

export const meetingState = atom<Meeting | undefined>({
  key: "MeetingState",
  default: undefined,
});

/**
 * if the value is true, means that I need to notify the user that a new
 * message is received
 */
export const chatNotificationStatus = atom<boolean>({
  key: "chatNotificationStatus",
  default: true,
});

/**
 * if the value is true, hand is raised
 * if the value is false, hand is down
 */
export const raiseHandStatus = atom<boolean>({
  key: "raiseHandStatus",
  default: false,
});

export const chatViewState = atom<"open" | "closed">({
  key: "chatViewState",
  default: "closed",
});
