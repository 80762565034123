import { generatePath } from "react-router-dom";
import { RouteName, routesPath, RouteType } from "../interfaces/portalPages";
import { Classroom } from "../pages/Classroom";
import { Course } from "../pages/Course";
import { Courses } from "../pages/Courses";
import { ForgotPassword } from "../pages/ForgotPassword";
import { Lesson } from "../pages/Lesson";
import { Lessons } from "../pages/Lessons";
import { LessonVideo } from "../pages/LessonVideo";
import { Login } from "../pages/Login";
import { Test } from "../pages/Test";
import { TestBySubjectReview } from "../pages/TestBySubjectReview";
import { TestsByExam } from "../pages/TestsByExam";
import { TestsBySubject } from "../pages/TestsBySubject";
import { TestsReview } from "../pages/TestsReview";

export const portalPages: RouteType[] = [
  {
    name: "login",
    path: routesPath.login,
    pageComponent: <Login />,
    exact: true,
    protected: false,
  },
  {
    name: "forgot-password",
    path: routesPath["forgot-password"],
    pageComponent: <ForgotPassword />,
    exact: true,
    protected: false,
  },
  {
    name: "lessons",
    path: routesPath.lessons,
    pageComponent: <Lessons />,
    exact: true,
    protected: true,
  },
  {
    name: "lesson",
    path: routesPath.lesson,
    pageComponent: <Lesson />,
    exact: true,
    protected: true,
  },
  {
    name: "lesson-meeting",
    path: routesPath["lesson-meeting"],
    pageComponent: <LessonVideo />,
    exact: true,
    protected: true,
  },
  {
    name: "courses",
    path: routesPath.courses,
    pageComponent: <Courses />,
    exact: true,
    protected: true,
  },
  {
    name: "course",
    path: routesPath.course,
    pageComponent: <Course />,
    exact: true,
    protected: true,
  },
  {
    name: "classroom",
    path: routesPath.classroom,
    pageComponent: <Classroom />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.subject",
    path: routesPath["tests.subject"],
    pageComponent: <TestsBySubject />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.subject.review.subtopicId",
    path: routesPath["tests.subject.review.subtopicId"],
    pageComponent: <TestBySubjectReview />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.subject.review",
    path: routesPath["tests.subject.review"],
    pageComponent: <TestBySubjectReview />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.exam",
    path: routesPath["tests.exam"],
    pageComponent: <TestsByExam />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.details",
    path: routesPath["tests.details"],
    pageComponent: <Test />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.review",
    path: routesPath["tests.review"],
    pageComponent: <Test />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.retry",
    path: routesPath["tests.retry"],
    pageComponent: <Test />,
    exact: true,
    protected: true,
  },
  {
    name: "tests.revision",
    path: routesPath["tests.revision"],
    pageComponent: <TestsReview />,
    exact: true,
    protected: true,
  },
];

export const getRoutePath = (
  name: RouteName,
  params?: Record<string, string>
): string => {
  const page = portalPages.find((p) => p.name === name);

  if (!page) {
    throw new Error(`No page set with name: ${name}`);
  }

  return generatePath(page.path, params);
};

export function getPublicPages(): RouteType[] {
  return portalPages.filter((p) => !p.protected);
}

export function getProtectedPages(): RouteType[] {
  return portalPages.filter((p) => p.protected);
}
