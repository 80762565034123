import {
  Lesson,
  LessonContent,
  LessonContentContent,
} from "../interfaces/courses";
import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import { CustomError } from "../utils/CustomError";
import { apiFetch } from "./api";

export async function getLessons(courseId: string): Promise<Lesson[]> {
  try {
    const data: Record<string, any> = await apiFetch(
      `/lessons?courseId=${courseId}`,
      "GET"
    );

    return data.lessons;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function getContent(lessonId: string): Promise<LessonContent> {
  try {
    const data: Record<string, any> = await apiFetch(
      `/lessons/${lessonId}`,
      "GET"
    );
    return data.lesson;
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}

export async function markAsViewed(
  content: LessonContentContent,
  answerId?: string
): Promise<void> {
  try {
    const params = new URLSearchParams({
      contentId: content.id.toString(),
    });
    if (answerId) {
      params.set("answerId", answerId);
    }
    await apiFetch(`/lessons/content?${params.toString()}`, "POST");
  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}
