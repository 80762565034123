import { useMeeting } from "@videosdk.live/react-sdk";
import { ParticipantAudioPlayer } from "./ParticipantAudioPlayer";

export const ParticipantsAudioPlayer = () => {
  const mMeeting = useMeeting();

  const participants = mMeeting?.participants;
  const participantsArray = Array.from(participants, function (entry) {
    return { key: entry[0], value: entry[1] };
  });

  return (
    <span>
      {participantsArray.map((participantId, value) => (
        <ParticipantAudioPlayer
          participantId={participantId.key}
          participantKey={`participant_audio_${participantId.key}_${value}`}
        />
      ))}
    </span>
  );
};
