/* eslint-disable no-console */
import { useRecoilValue } from "recoil";
import { meetingState } from "../../../state/meeting";
import { ChatView } from "./ChatView";
import { ContentView } from "./ContentView";
import { TeacherView } from "./TeacherView";
import { VideoController } from "./VideoController";
import { ParticipantsAudioPlayer } from "./ParticipantsAudioPlayer";
import { useEffect, useState } from "react";
import { getFirebaseDatabase, getReference } from "../../../utils/firebase";
import { getPreffixFirebase } from "../../../api/api";
import { useParams } from "react-router-dom";
import { onValue } from "firebase/database";
import { useUser } from "../../../hooks/useUser";
import Draggable from "react-draggable";
import { usePubSub } from "@videosdk.live/react-sdk";
import { ref, set } from "firebase/database";
import { ViewfinderCircleIcon } from "@heroicons/react/24/solid";
import { MeetingButton } from "../../Presentational/Meeting/MeetingButton";

interface VideoWrapperProps {
  modeComplete?: boolean;
}

export const VideoWrapper = ({ modeComplete = true }: VideoWrapperProps) => {
  const meeting = useRecoilValue(meetingState);
  const [sizeFullscreen, setSizeFullscreen] = useState(false);
  let { lessonId } = useParams();
  const [participantIdChange, setParticipantIdChange] = useState(null);
  const [videoConfig, setVideoConfig] = useState({ draggable: true, width: "280px", height: "auto" });
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const { user } = useUser();
  let enableAudio = participantIdChange == null || participantIdChange == user?.username;
  let forceFullscreen = participantIdChange == user?.username;

  const toggleSize = () => {
    if (sizeFullscreen) {
      setVideoConfig({ draggable: true, width: "256px", height: "auto" });
      setPosition({ x: 20, y: 20 });
    } else {
      setVideoConfig({ draggable: true, width: "900px", height: "auto" });
      setPosition({ x: 20, y: 20 });
    }
    setSizeFullscreen(!sizeFullscreen);
  };

  if (lessonId === undefined && meeting?.session?.sessionId !== undefined && meeting?.session?.sessionId != null) {
    lessonId = meeting?.session?.sessionId.toString();
  }

  const handleDrag = (e: any, data: any) => {
    setPosition({ x: data.x, y: data.y });
  };

  usePubSub("SET_SCREEN_SIZE", {
    onMessageReceived: (e) => {
      const messageObject = JSON.parse(e.message);
      if (messageObject.participantId === user?.username) {
        switch (messageObject.size) {
          case 2:
            setVideoConfig({ draggable: true, width: "900px", height: "auto" });
            setPosition({ x: 20, y: 20 });
            break;
          case 3:
            setVideoConfig({ draggable: false, width: "100%", height: "100%" });
            setPosition({ x: 0, y: 0 });
            break;
          default:
            setVideoConfig({ draggable: true, width: "256px", height: "auto" });
            setPosition({ x: 20, y: 20 });
            break;
        }
      }
    },
  });

  /**
   * @description This method has all the logic used to integrate with Firebase
   * and get the values for content and video status every time they are modified
   */
  useEffect(() => {
    const privateChannel = getReference(`privateChannel${getPreffixFirebase()}/session${lessonId}`);
    const privateChannelChange = onValue(privateChannel, (snapshot: any) => {
      const data = snapshot.val();
      const participantIdChange = data?.participantId;
      // Mode general
      if (participantIdChange == null) {
        setParticipantIdChange(null);
        setVideoConfig({ draggable: true, width: "256px", height: "auto" });
        setPosition({ x: 20, y: 20 });
        // Mode private
      } else {
        setParticipantIdChange(participantIdChange);
      }
      enableAudio = participantIdChange == null || participantIdChange == user?.username;
      forceFullscreen = participantIdChange == user?.username;
    });

    return () => {
      privateChannelChange();
    };
  }, []);

  // remove private channel call firebase reference on exit page
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      set(ref(getFirebaseDatabase(), `privateChannel${getPreffixFirebase()}/session${lessonId}`), {
        participantId: null,
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  useEffect(() => {
    if (forceFullscreen) {
      setVideoConfig({ draggable: true, width: "256px", height: "auto" });
      setPosition({ x: 20, y: 20 });
    }
  }, [forceFullscreen]);

  if (modeComplete) {
    return (
      <div className="relative w-full h-full">
        <div className="pb-20 p-4 flex flex-grow h-full max-h-full justify-center gap-2">
          <ContentView />
          {meeting?.session?.type === "online" && (
            <>
              {enableAudio && <ParticipantsAudioPlayer />}
              <TeacherView enableAudio={enableAudio} modeComplete={modeComplete}></TeacherView>
              <ChatView />
            </>
          )}
        </div>
        <VideoController modeComplete={modeComplete} />
      </div>
    );
  } else {
    const videoContent = (
      <div
        style={{
          width: videoConfig.width,
          height: videoConfig.height,
          zIndex: 999,
          backgroundColor: "#212121",
          position: "fixed",
          cursor: videoConfig.draggable ? "move" : "not-allowed",
        }}
      >
        {!forceFullscreen ? (
          <div
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              zIndex: 1000,
            }}
          >
            <MeetingButton onClick={toggleSize} className={"bg-gray-50"}>
              <ViewfinderCircleIcon className="h-5 text-black" />
            </MeetingButton>
          </div>
        ) : null}
        <TeacherView enableAudio={enableAudio} modeComplete={modeComplete}></TeacherView>
        <VideoController modeComplete={modeComplete} />
      </div>
    );

    return (
      <div>
        {meeting?.session?.type === "online" && (
          <>
            {enableAudio && <ParticipantsAudioPlayer />}
            <>
              {videoConfig.draggable ? (
                <Draggable position={position} onDrag={handleDrag}>
                  {videoContent}
                </Draggable>
              ) : (
                videoContent
              )}
            </>
          </>
        )}
      </div>
    );
  }
};
