import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { getStudentCourses } from "../api/courses";
import { Course } from "../interfaces/courses";
import { selectedCourseState } from "../state/global";
import { useNavigate } from "react-router-dom";
import { getRoutePath } from "../utils/portalPages";

interface hookProps {
  isLoadingCourses: boolean;
  error?: string;
  courses: Course[];
  selectedCourse?: Course;
}

export const hookState = atom<hookProps>({
  key: "coursesHookState",
  default: {
    isLoadingCourses: false,
    courses: [],
  },
});

export function useCourses() {
  const { courseId } = useParams();
  const [state, setState] = useRecoilState(hookState);
  const navigate = useNavigate();

  const reset = () => {
    setState({
      isLoadingCourses: false,
      courses: [],
    });
  };

  useEffect(() => {
    const courseIdNum = courseId ? parseInt(courseId, 10) : courseId;
    if (
      courseIdNum &&
      !state.isLoadingCourses &&
      state.courses?.length > -1 &&
      courseIdNum !== state.selectedCourse?.id
    ) {
      const course = state.courses.find((c) => c.id === courseIdNum);
      // Si el curso no está disponible, haremos redirección
      if (course === undefined) {
        navigate(getRoutePath("login"));
      } else {
        setState((prev) => {
          return { ...prev, selectedCourse: course };
        });
      }
    }
  }, [courseId, state.courses]);

  const getCourses = useCallback(async (): Promise<void> => {
    if (state.isLoadingCourses || state.courses.length) return;
    setState((prev) => {
      return { ...prev, isLoadingCourses: true, error: undefined };
    });
    try {
      let courses: Course[] = [];
      const coursesResponse = await getStudentCourses();

      coursesResponse.selectionType.types.forEach((t) => {
        if (t.type === "theory") {
          courses = [...courses, ...t.subtypes.courses];
        }
      });

      // Ignoramos los cursos que tienen enable a false
      courses = courses.filter((course) => course.enable == true);

      setState((prev) => {
        return { ...prev, isLoadingCourses: false, courses };
      });
    } catch (error: any) {
      setState((prev) => {
        return { ...prev, isLoadingCourses: false, error: error.message };
      });
    }
  }, [state]);

  // useEffect(() => {
  //   getCourses();
  // }, []);

  const setSelectedCourse = (course?: Course) => {
    setState((prev) => {
      return { ...prev, selectedCourse: course };
    });
  };

  return {
    reset,
    setSelectedCourse,
    getCourses,
    ...state,
  };
}
