import { ReactNode } from "react";

export type RouteName =
  | "login"
  | "logout"
  | "courses"
  | "course"
  | "lessons"
  | "lesson"
  | "tests.subject"
  | "tests.subject.review"
  | "tests.subject.review.subtopicId"
  | "tests.exam"
  | "tests.revision"
  | "tests.details"
  | "tests.review"
  | "tests.retry"
  | "classroom"
  | "lesson-meeting"
  | "forgot-password";

export type RouteType = {
  path: string;
  name: RouteName;
  pageComponent: ReactNode;
  exact?: boolean;
  protected: boolean;
};

export const routesPath: Record<RouteName, string> = {
  login: "/login",
  logout: "/logout",
  courses: "/cursos",
  course: "/cursos/:courseId",
  lessons: "/cursos/:courseId/lecciones",
  lesson: "/cursos/:courseId/lecciones/:lessonId",
  "tests.subject": "/cursos/:courseId/tests-temas",
  "tests.subject.review": "/cursos/:courseId/tests-temas/:subjectId/detalle",
  "tests.subject.review.subtopicId":
    "/cursos/:courseId/tests-temas/:subjectId/detalle/:subtopicId",
  "tests.exam": "/cursos/:courseId/test-examen",
  "tests.revision": "/cursos/:courseId/test-de-revision",
  "tests.details": "/cursos/:courseId/test/:testId",
  "tests.review": "/cursos/:courseId/test/:testId/revision",
  "tests.retry": "/cursos/:courseId/test/:testId/repetir",
  classroom: "/cursos/:courseId/clases",
  "lesson-meeting": "/cursos/:courseId/clase/:lessonId",
  "forgot-password": "/olvide-mi-clave",
};
