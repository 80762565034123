import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ToolBar } from "../../../Layout/ToolBar";
import { ZoomButtons } from "../ZoomButtons";
import { SendQuestionToChatButton } from "../SendQuestionToChatButton";

export function TopBar({ questionId }: { questionId?: number }) {
  const { t } = useTranslation();
  const redirectUrl = window.localStorage.getItem("test-dashboard");
  return (
    <ToolBar>
      <ZoomButtons />

      <div className="grow mr-10 flex justify-end space-x-3 align-bottom">
        <Link
          to={redirectUrl ?? ""}
          className="flex px-9 py-2 justify-center border border-transparent text-md font-medium rounded-md text-white bg-orange-torcal hover:bg-orange-700 disabled:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-torcal"
        >
          {t("Test.return")}
        </Link>
        <SendQuestionToChatButton
          questionId={questionId}
        ></SendQuestionToChatButton>
      </div>
    </ToolBar>
  );
}
