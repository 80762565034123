import { User } from "../interfaces/user";
import { USER_SESSION_STORAGE_KEY } from "./config";

export function saveUserInfo(userInfo: User) {
  sessionStorage.setItem(USER_SESSION_STORAGE_KEY, JSON.stringify(userInfo));
}

export function getUserInfo(): User | undefined {
  const userData = sessionStorage.getItem(USER_SESSION_STORAGE_KEY);
  if (userData) {
    return JSON.parse(userData);
  }
  return undefined;
}
export function cleanUserInfo() {
  sessionStorage.removeItem(USER_SESSION_STORAGE_KEY);
}
