import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useRef } from "react";
import { Button } from "../Button";
import Spinner from "../Layout/Spinner";

export default function SendMessage({
  isLoading,
  submitMessage,
}: {
  isLoading: boolean;
  submitMessage: (msg: string) => void;
}) {
  const chatInput = useRef<HTMLInputElement>(null);

  return (
    <div className="flex justify-between space-x-3 pt-2 bg-gray-200">
      <input
        ref={chatInput}
        autoFocus
        disabled={isLoading}
        name="message"
        id="message"
        placeholder="mensaje ..."
        onKeyDown={(event) => {
          if (
            event.key === "Enter" &&
            chatInput?.current?.value &&
            chatInput?.current?.value.length > 0
          ) {
            submitMessage(chatInput.current.value);
            chatInput.current.value = "";
          }
        }}
        className="w-full resize-none rounded-md border border-gray-light bg-white py-2 px-4 text-base font-medium text-gray-medium disabled:border-gray-100 disabled:placeholder-gray-200 placeholder-gray-300 outline-none focus:border-orange-tbg-orange-torcal focus:shadow-md"
      />
      <Button
        onClick={() => {
          if (
            chatInput?.current?.value &&
            chatInput?.current?.value.length > 0
          ) {
            submitMessage(chatInput.current.value);
            chatInput.current.value = "";
          }
        }}
        className="hover:shadow-form px-2 py-2"
        color="orange"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner
            className="h-6 self-center place-self-center mr-0.5 ml-0.5"
            lineColor="text-white"
          />
        ) : (
          <PaperAirplaneIcon className="h-6 fill-white mr-0.5 ml-0.5" />
        )}
      </Button>
    </div>
  );
}
