import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingBar from "../components/Layout/LoadingBar";
import { ToolBar } from "../components/Layout/ToolBar";
import { AvailableTestBar } from "../components/Test/AvailableTestBar";
import { ExistentTestTable } from "../components/Test/ExistentTestTable";
import { FinishTestModal } from "../components/Test/FinishTestModal";
import { useTest } from "../hooks/useTest";
import { useTests } from "../hooks/useTests";
import { CreatedTest } from "../interfaces/tests";
import { getRoutePath } from "../utils/portalPages";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useUser } from "../hooks/useUser";

interface ViewState {
  error?: string;
  showModal: boolean;
  isRegeneratingTest: boolean;
  isCreateExamTestLoading: boolean;
  isCreateErrorTestLoading: boolean;
  test?: CreatedTest;
  testType?: "review" | "failed";
  failedTestType?: "review";
}

export function TestsReview() {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [viewState, setViewState] = useState<ViewState>({
    showModal: false,
    isRegeneratingTest: false,
    isCreateExamTestLoading: false,
    isCreateErrorTestLoading: false,
  });
  const { isLoadingTestInfo, isLoadingTestReview, infoReview, errorTestInfo, reviewReview, errorTestReview } = useTests();
  const { createTest, terminateTest } = useTest();
  const { setUserMetadata } = useUser();

  setUserMetadata({ currentPage: "Tests de repaso", currentLesson: null, currentTest: null });

  const navigateToTest = (testId: string): void => {
    if (!courseId) return;
    window.localStorage.setItem("test-dashboard", location.pathname);
    navigate(
      getRoutePath("tests.details", {
        courseId: courseId,
        testId,
      })
    );
  };

  const onCreateTest = async (testType: "review" | "failed", failedTestType?: "review") => {
    if (!courseId) return;

    try {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: !!(testType && !failedTestType),
        isCreateErrorTestLoading: !!(testType && failedTestType),
      });

      const newTest = await createTest(courseId, testType, failedTestType);
      if (newTest.incompleteOld) {
        setViewState({
          ...viewState,
          testType,
          failedTestType,
          isCreateExamTestLoading: false,
          isCreateErrorTestLoading: false,
          isRegeneratingTest: false,
          test: newTest,
          showModal: true,
        });
      } else {
        navigateToTest(newTest.id);
      }
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error"),
      });
    }
  };

  const cleanAndGenerateNewTest = async () => {
    if (!viewState.testType || !viewState.test || !courseId) return;
    try {
      setViewState({ ...viewState, isRegeneratingTest: true });
      await terminateTest(viewState.test.id);
      onCreateTest(viewState.testType, viewState.failedTestType);
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error-finishing-test"),
      });
    }
  };

  if (errorTestInfo || errorTestReview) {
    return (
      <ToolBar>
        <p className="text-lg font-light">{t("Test.error-loading-test")}</p>
      </ToolBar>
    );
  }

  if (isLoadingTestInfo || isLoadingTestReview) {
    return <LoadingBar text={t("Test.loading-tests")} />;
  }

  if (!infoReview) {
    return (
      <ToolBar>
        <p className="text-lg font-light">{t("Test.not-available-test-revision")}</p>
      </ToolBar>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      {courseId && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", { courseId: courseId! }),
            },
            { name: t("Header.Tests.revision") },
          ]}
        />
      )}
      {!viewState.error?.length ?? <p className="w-full text-white py-4 bg-red-500 text-center">{viewState.error}</p>}
      <AvailableTestBar
        isCreateEnabled={viewState.isCreateExamTestLoading || viewState.isCreateErrorTestLoading}
        isCreateLoading={viewState.isCreateExamTestLoading}
        onCreateTest={() => {
          onCreateTest("review");
        }}
        maxTest={infoReview?.maxTest}
        testsDone={infoReview?.testsDone}
        title={t("Test.available-test-exam")}
        ctaText={t("Test.create-test-exam")}
      />

      <div className="bg-white flex flex-col justify-start p-6 md:py-10 md:px-14 shadow-lg h-[460px]">
        <ExistentTestTable reviewCategory={reviewReview} tooltipText={t("Test.repeat-no-allowed-msg")} />
      </div>

      <AvailableTestBar
        isCreateEnabled={viewState.isCreateExamTestLoading || viewState.isCreateErrorTestLoading}
        isCreateLoading={viewState.isCreateErrorTestLoading}
        onCreateTest={() => {
          onCreateTest("failed", "review");
        }}
        maxTest={infoReview?.testsFalloPending}
        title={t("Test.not-available-test-error")}
        ctaText={t("Test.create-test-error")}
      />
      <FinishTestModal
        isOpen={viewState.showModal}
        isRegeneratingTest={viewState.isRegeneratingTest || viewState.isCreateErrorTestLoading || viewState.isCreateExamTestLoading}
        onClose={() =>
          setViewState({
            ...viewState,
            showModal: false,
          })
        }
        onContinue={() => {
          if (!viewState.test) return;
          navigateToTest(viewState.test.id);
        }}
        onRegenerate={cleanAndGenerateNewTest}
      />
    </div>
  );
}
