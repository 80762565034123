import {
  MicrophoneIcon,
  PhoneIcon,
  VideoCameraIcon,
  VideoCameraSlashIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Video, { LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import Modal from "../../Modal/Modal";
import { Participant } from "./Participant";

export function VideoRoom({
  roomName,
  token,
  onCloseVideo,
}: {
  roomName: string;
  token: string;
  onCloseVideo: () => void;
}) {
  const [room, setRoom] = useState<Video.Room | undefined>();
  const [participants, setParticipants] = useState<Video.Participant[]>([]);
  const [isMicroON, setIsMicroON] = useState<boolean>(false);
  const [isCamON, setIsCamON] = useState<boolean>(false);

  useEffect(() => {
    const participantConnected = (participant: Video.Participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: Video.Participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
    }).then((room: Video.Room) => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            // @ts-ignore
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const onDisconnect = () => {
    room?.disconnect();
    onCloseVideo();
  };

  return (
    <Modal
      isOpen={true}
      closeModal={onDisconnect}
      title="Video llamada"
      size="max-w-5xl"
    >
      <div className="relative w-full">
        <div className="w-full mb-5">
          {participants.map((participant) => (
            <Participant key={participant.sid} participant={participant} />
          ))}
        </div>

        <div className="w-52 absolute bottom-5 right-5  border-4 border-gray-900 rounded-lg">
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
            />
          ) : null}
        </div>

        <div className="flex justify-center space-x-5">
          <button
            onClick={() => {
              if (room && room.localParticipant.tracks) {
                room.localParticipant.tracks.forEach((t) => {
                  if (t.kind === "video") {
                    (t.track as LocalVideoTrack).enable(isCamON);
                  }
                });

                setIsCamON(!isCamON);
              }
            }}
            className="rounded-full border border-gray-800 p-2 hover:border-gray-300"
          >
            {isCamON ? (
              <VideoCameraSlashIcon className="h-8 hover:stroke-gray-300" />
            ) : (
              <VideoCameraIcon className="h-8" />
            )}
          </button>
          <button
            onClick={() => {
              if (room && room.localParticipant.tracks) {
                room.localParticipant.tracks.forEach((t) => {
                  if (t.kind === "audio") {
                    (t.track as LocalAudioTrack).enable(isMicroON);
                  }
                });

                setIsMicroON(!isMicroON);
              }
            }}
            className="rounded-full border border-gray-800 p-2 hover:border-gray-300 group"
          >
            {isMicroON ? (
              <MicrophoneSlashIcon className="h-6 hover:stroke-gray-300" />
            ) : (
              <MicrophoneIcon className="h-8 hover:stroke-gray-300" />
            )}
          </button>
          <button
            onClick={onDisconnect}
            className="rounded-full border border-gray-800 p-2 hover:border-gray-300 group"
          >
            <PhoneIcon className="h-8 rotate-[135deg] hover:stroke-gray-300" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

function MicrophoneSlashIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className={className}
    >
      <path d="M479.1 256l-.0004-48c0-8.801-7.199-16-15.1-16c-8.801 0-15.1 7.199-15.1 16l.0002 48c0 6.172-1.047 12.06-1.895 17.1l28.12 22.2C477.6 283.3 479.1 269.1 479.1 256zM255.1 96c0-35.25 28.75-64 63.1-64S383.1 60.75 383.1 96v128.1l31.1 25.26L415.1 96c0-53-43-96-95.1-96S223.1 43 223.1 96l-.0001 2.645l31.1 25.26V96zM633.9 483.4L25.92 3.42C18.98-2.033 8.92-.8301 3.436 6.061C-2.033 12.1-.8457 23.06 6.076 28.54L614.1 508.6C617 510.9 620.5 512 623.1 512c4.734 0 9.422-2.094 12.58-6.078C642 498.1 640.8 488.9 633.9 483.4zM399.1 480h-63.1v-65c21.21-2.135 41.09-8.607 59.05-18.2l-27.98-22.09c-17.16 6.842-35.98 10.34-55.82 9.045C243.2 379.1 191.1 319.6 191.1 251.5V236.5L159.1 211.2v39.15c0 83.38 61.62 156.1 143.1 164.4V480H239.1c-8.801 0-16 7.199-16 16C223.1 504.8 231.2 512 239.1 512h159.1c8.801 0 15.1-7.201 15.1-15.1C415.1 487.2 408.8 480 399.1 480z" />
    </svg>
  );
}
