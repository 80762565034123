import React, { createContext, useContext, useState, ReactNode } from "react";

interface SDKMeetingContextType {
  sdkMeeting: any;
  setSDKMeeting: (sdkMeeting: any) => void;
}

const SDKMeetingContext = createContext<SDKMeetingContextType | undefined>(undefined);

export const SDKMeetingProvider = ({ children }: { children: ReactNode }) => {
  const [sdkMeeting, setSDKMeeting] = useState<any>(null);

  return <SDKMeetingContext.Provider value={{ sdkMeeting, setSDKMeeting }}>{children}</SDKMeetingContext.Provider>;
};

export const useSDKMeeting = () => {
  const context = useContext(SDKMeetingContext);
  if (context === undefined) {
    throw new Error("useSDKMeeting must be used within a SDKMeetingProvider");
  }
  return context;
};
