import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import Spinner from "../Layout/Spinner";
import Modal from "../Modal/Modal";

export function FinishTestModal({
  isOpen,
  isRegeneratingTest,
  onClose,
  onContinue,
  onRegenerate,
}: {
  isOpen: boolean;
  isRegeneratingTest: boolean;
  onClose: () => void;
  onContinue: () => void;
  onRegenerate: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      title={t("Test.previous-tests-existent")}
    >
      <div className="my-5 flex flex-col">
        <p>{t("Test.continue-or-finalize")}</p>
        <div
          className={classNames(
            "flex mt-5",
            isRegeneratingTest ? "" : "space-x-5"
          )}
        >
          <Button
            color="orange"
            onClick={onContinue}
            className={classNames(isRegeneratingTest ? "hidden" : "")}
          >
            {t("Test.continue")}
          </Button>
          <Button
            color="orange"
            disabled={isRegeneratingTest}
            onClick={onRegenerate}
            className={classNames(
              "text-xs px-4 leading-6",
              isRegeneratingTest ? "w-full" : ""
            )}
          >
            {isRegeneratingTest ? (
              <>
                <Spinner />
                {t("Test.finished")}
              </>
            ) : (
              t("Test.finish-and-restart")
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
