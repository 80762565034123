import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBar from "../components/Layout/LoadingBar";
import { ToolBar } from "../components/Layout/ToolBar";
import { AvailableTestBar } from "../components/Test/AvailableTestBar";
import { ExistentTestTable } from "../components/Test/ExistentTestTable";
import { FinishTestModal } from "../components/Test/FinishTestModal";
import { useCourses } from "../hooks/useCourses";
import { useTest } from "../hooks/useTest";
import { useTests } from "../hooks/useTests";
import { CreatedTest } from "../interfaces/tests";
import { getRoutePath } from "../utils/portalPages";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useUser } from "../hooks/useUser";

interface ViewState {
  error?: string;
  showModal: boolean;
  isRegeneratingTest: boolean;
  isCreateExamTestLoading: boolean;
  isCreateErrorTestLoading: boolean;
  test?: CreatedTest;
  testType?: "exam" | "failed" | "examReduced";
  failedTestType?: "exam";
}

export function TestsByExam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [viewState, setViewState] = useState<ViewState>({
    showModal: false,
    isRegeneratingTest: false,
    isCreateExamTestLoading: false,
    isCreateErrorTestLoading: false,
  });
  const { infoExam, errorTestInfo, reviewExam, errorTestReview } = useTests();
  const { createTest, terminateTest } = useTest();
  const { setUserMetadata } = useUser();

  const { selectedCourse } = useCourses();

  setUserMetadata({ currentPage: "Tests de examen", currentLesson: null, currentTest: null });

  const navigateToTest = (testId: string): void => {
    if (!selectedCourse) return;
    window.localStorage.setItem("test-dashboard", location.pathname);
    navigate(
      getRoutePath("tests.details", {
        courseId: selectedCourse.id.toString(),
        testId,
      })
    );
  };

  const onCreateTest = async (testType: "exam" | "examReduced" | "failed", failedTestType?: "exam") => {
    if (!selectedCourse) return;

    try {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: !!(testType && !failedTestType),
        isCreateErrorTestLoading: !!(testType && failedTestType),
      });

      const newTest = await createTest(selectedCourse.id.toString(), testType, failedTestType);
      if (newTest.incompleteOld) {
        setViewState({
          ...viewState,
          testType,
          failedTestType,
          isCreateExamTestLoading: false,
          isCreateErrorTestLoading: false,
          isRegeneratingTest: false,
          test: newTest,
          showModal: true,
        });
      } else {
        navigateToTest(newTest.id);
      }
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error"),
      });
    }
  };

  const cleanAndGenerateNewTest = async () => {
    if (!viewState.testType || !viewState.test || !selectedCourse) return;
    try {
      setViewState({ ...viewState, isRegeneratingTest: true });
      await terminateTest(viewState.test.id);
      onCreateTest(viewState.testType, viewState.failedTestType);
    } catch (error) {
      setViewState({
        ...viewState,
        isCreateExamTestLoading: false,
        isCreateErrorTestLoading: false,
        isRegeneratingTest: false,
        showModal: false,
        error: t("Test.error-finishing-test"),
      });
    }
  };

  if (errorTestInfo || errorTestReview) {
    return (
      <ToolBar>
        <p className="text-lg font-light">{t("Test.error-loading-test")}</p>
      </ToolBar>
    );
  }

  if (infoExam === undefined || reviewExam === undefined) {
    return <LoadingBar text={t("Test.loading-tests")} />;
  }

  if (!infoExam) {
    return (
      <ToolBar>
        <p className="text-lg font-light">{t("Test.not-available-test-exam")}</p>
      </ToolBar>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      {selectedCourse && (
        <Breadcrumbs
          crumbs={[
            {
              name: t("Header.home"),
              path: getRoutePath("course", {
                courseId: selectedCourse?.id.toString()!,
              }),
            },
            { name: t("Header.Tests.exam") },
          ]}
        />
      )}
      {!viewState.error?.length ?? <p className="w-full text-white py-4 bg-red-500 text-center">{viewState.error}</p>}
      <AvailableTestBar
        hasReduced={infoExam.hasReduced}
        isCreateEnabled={viewState.isCreateExamTestLoading || viewState.isCreateErrorTestLoading}
        isCreateLoading={viewState.isCreateExamTestLoading}
        onCreateTest={() => {
          onCreateTest("exam");
        }}
        onCreateReducedTest={() => {
          onCreateTest("examReduced");
        }}
        maxTest={infoExam?.maxTest}
        testsDone={infoExam?.testsDone}
        title={t("Test.available-test-exam")}
        ctaText={t("Test.create-test-exam")}
      />

      <div className="bg-white flex flex-col justify-start p-6 md:py-10 md:px-14 shadow-lg h-[460px]">
        <ExistentTestTable reviewCategory={reviewExam} tooltipText={t("Test.repeat-no-allowed-msg")} />
      </div>

      <AvailableTestBar
        isCreateEnabled={viewState.isCreateExamTestLoading || viewState.isCreateErrorTestLoading}
        isCreateLoading={viewState.isCreateErrorTestLoading}
        onCreateTest={() => {
          onCreateTest("failed", "exam");
        }}
        maxTest={infoExam?.testsFalloPending}
        title={t("Test.not-available-test-error")}
        ctaText={t("Test.create-test-error")}
      />
      <FinishTestModal
        isOpen={viewState.showModal}
        isRegeneratingTest={viewState.isRegeneratingTest || viewState.isCreateErrorTestLoading || viewState.isCreateExamTestLoading}
        onClose={() =>
          setViewState({
            ...viewState,
            showModal: false,
          })
        }
        onContinue={() => {
          if (!viewState.test) return;
          navigateToTest(viewState.test.id);
        }}
        onRegenerate={cleanAndGenerateNewTest}
      />
    </div>
  );
}
