import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCourses } from "../../../hooks/useCourses";
import { useTests } from "../../../hooks/useTests";
import { TestResult } from "../../../interfaces/tests";
import { getRoutePath } from "../../../utils/portalPages";
import { Button } from "../../Button";
import Spinner from "../../Layout/Spinner";
import Modal from "../../Modal/Modal";

export function FinishModal({
  title,
  setShowModal,
  showModal,
  isFinishingTest,
  finishTest,
  showError = false,
  summary,
}: {
  title: string;
  setShowModal: (value: boolean) => void;
  summary?: TestResult;
  showError?: boolean;
  showModal: boolean;
  isFinishingTest: boolean;
  finishTest: () => void;
}) {
  const { courseId, testId } = useParams();
  const navigate = useNavigate();
  const { refreshTestInfo } = useTests();
  const { selectedCourse } = useCourses();

  const getBlock = () => {
    if (showError) {
      return <ErrorBlock closeModal={closeModal} />;
    } else if (summary) {
      return (
        <SummaryBlock
          summary={summary}
          closeModal={closeModal}
          sendToReview={sendToReview}
        />
      );
    }
    return (
      <DefaultBlock
        finishTest={finishTest}
        isFinishingTest={isFinishingTest}
        closeModal={closeModal}
      />
    );
  };
  const closeModal = () => {
    if (summary && selectedCourse) {
      refreshTestInfo();
      const previousRoute = window.localStorage.getItem("test-dashboard");
      navigate(previousRoute as string);
    }
    setShowModal(false);
  };

  const sendToReview = () => {
    refreshTestInfo();
    navigate(
      getRoutePath("tests.review", {
        courseId: courseId ?? "",
        testId: testId ?? "",
      })
    );
  };

  return (
    <Modal isOpen={showModal} closeModal={closeModal} title={title}>
      <div className="my-5 flex flex-col">{getBlock()}</div>
    </Modal>
  );
}

function ErrorBlock({ closeModal }: { closeModal: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="mt-5 space-y-5">
      <p>{t("Test.error")}</p>
      <Button onClick={closeModal}>Cerrar</Button>
    </div>
  );
}

function SummaryBlock({
  summary,
  closeModal,
  sendToReview,
}: {
  summary: TestResult;
  closeModal: () => void;
  sendToReview: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="space-y-5">
      <div className="text-center justify-center flex flex-col">
        {summary.face === "happy" ? (
          <>
            <CheckCircleIcon className="h-20 fill-green-torcal" />
            <p className="text-green-torcal">{t("Test.passed  ")}</p>
          </>
        ) : (
          <>
            <XCircleIcon className="h-20 fill-red-500" />
            <p className="text-red-500">{t("Test.not-approved")}</p>
          </>
        )}
      </div>
      <ul className="text-gray-dark space-y-2">
        <li className="flex justify-between pr-2">
          <span>{t("Test.answers-ok")}</span>
          <span>{summary.rightQuestions}</span>
        </li>
        <li className="flex justify-between pr-2">
          <span>{t("Test.answers-not-ok")}</span>
          <span>{summary.failedQuestions}</span>
        </li>
        <li className="flex justify-between pr-2">
          <span>{t("Test.not-answered")}</span>
          <span>{summary.unansweredQuestions}</span>
        </li>
      </ul>
      <div className="flex justify-around">
        <Button className="w-1/3 text-xs" onClick={closeModal}>
          {t("Test.close")}
        </Button>
        <Button
          color="orange"
          onClick={sendToReview}
          className={
            "w-1/3 text-xs leading-6 font-medium text-center border border-transparent rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 py-1 px-2 bg-orange-torcal hover:bg-orange-700 disabled:bg-orange-700 focus:ring-orange-torcal"
          }
        >
          {t("Test.review")}
        </Button>
      </div>
    </div>
  );
}

function DefaultBlock({
  closeModal,
  isFinishingTest,
  finishTest,
}: {
  closeModal: () => void;
  isFinishingTest: boolean;
  finishTest: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex mt-5 space-x-5">
      <Button
        disabled={isFinishingTest}
        onClick={finishTest}
        className={classNames(
          "py-2 px-4 leading-6",
          isFinishingTest ? "w-full" : ""
        )}
      >
        {isFinishingTest ? (
          <>
            <Spinner />
            {t("Test.finished")}
          </>
        ) : (
          t("Test.finalize")
        )}
      </Button>
      <Button
        onClick={closeModal}
        className={classNames("py-2 px-4 leading-6", {
          hidden: isFinishingTest,
        })}
        color="orange"
      >
        {t("Test.cancel")}
      </Button>
    </div>
  );
}
