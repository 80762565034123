import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import Spinner from "../components/Layout/Spinner";
import { useUser } from "../hooks/useUser";
import { getRoutePath } from "../utils/portalPages";

export function Login() {
  const { t } = useTranslation();
  const { isAuthenticated, login, isLoading, error } = useUser();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate(getRoutePath("courses"));
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await login(username, password);
  };

  return (
    <div className="container m-auto">
      <div
        className="w-full h-full left-0 top-0 absolute -z-10 bg-green-torcal bg-cover"
        style={{
          backgroundImage: "url('/loginBackground.jpg')",
        }}
      ></div>
      <div className="h-screen flex flex-col justify-center z-10">
        <div className="w-full xl:w-3/5 lg:w-11/12 flex m-auto">
          <div className="w-full h-auto bg-green-torcal hidden lg:block lg:w-1/2 p-10">
            <div
              className="w-full h-full bg-center bg-no-repeat"
              style={{
                backgroundImage: 'url("/torcalLogoWhite.svg")',
                backgroundColor: "#13A538",
              }}
            ></div>
          </div>
          <div className="w-full lg:w-1/2 bg-white p-5 pt-16 pb-14">
            <h3 className="text-xl text-center">{t("Login.login")}</h3>
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded space-y-3"
              onSubmit={onSubmit}
            >
              <div>
                <label className="block mb-2 text-lg" htmlFor="user">
                  {t("Login.user")}
                </label>
                <input
                  required
                  name="user"
                  id="user"
                  type="text"
                  autoComplete="user"
                  placeholder={t("Login.user")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUsername(event.target.value);
                  }}
                  className="bg-white w-full py-3 px-2 text-md leading-tight text-gray-light border border-gray-light rounded focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg" htmlFor="password">
                  {t("Login.password")}
                </label>
                <input
                  required
                  name="password"
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder={t("Login.password")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  className="bg-white w-full py-3 px-2 text-md leading-tight text-gray-light border border-gray-light rounded focus:outline-none focus:shadow-outline"
                />
              </div>
              <p className="text-red-500 font-light text-xs text-right">
                {error}
              </p>
              <div className="flex justify-end">
                <Link
                  to={getRoutePath("forgot-password")}
                  className="text-xs text-gray-medium align-baseline hover:text-gray-dark right-0"
                >
                  {t("Login.did-you-forgot-password")}
                </Link>
              </div>

              <div className="text-center">
                <Button
                  disabled={isLoading}
                  type="submit"
                  className="w-full"
                  color="orange"
                >
                  {isLoading && <Spinner />}
                  {t("Login.sign-in")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
