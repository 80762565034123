import { XMarkIcon } from "@heroicons/react/24/solid";
import { ChatMessage } from "../../interfaces/chat";

export default function BubbleReply({
  replyTo,
  onCancelReply,
}: {
  replyTo?: ChatMessage;
  onCancelReply: () => void;
}) {
  if (!replyTo) {
    return null;
  }

  return (
    <div className="relative bg-gray-100 flex flex-col w-full p-2 rounded-xl">
      <button
        className="absolute right-2 hover:bg-gray-400 rounded-full"
        onClick={onCancelReply}
      >
        <XMarkIcon className="h-4 fill-gray-dark" />
      </button>
      <p className="text-left text-xs font-bold">{replyTo.author}</p>
      <p className="text-left text-xs mt-1 truncate">{replyTo.body}</p>
    </div>
  );
}
