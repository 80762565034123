import classNames from "classnames";
import { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  color?: "green" | "orange";
}

const getColors = (color: "green" | "orange"): string => {
  if (color === "green") {
    return "bg-green-torcal hover:bg-green-700 focus:ring-green-torcal";
  }
  return "bg-orange-torcal hover:bg-orange-700 focus:ring-orange-torcal";
};

export function Button({
  children,
  type = "button",
  disabled = false,
  className,
  onClick,
  color = "green",
}: ButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "flex px-9 py-2 justify-center border border-transparent text-md font-medium rounded-md text-white disabled:bg-gray-300 disabled:focus:outline-none focus:ring-2 focus:ring-offset-2",
        className,
        getColors(color)
      )}
    >
      {children}
    </button>
  );
}
