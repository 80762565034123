import { DEFAULT_ERROR_MESSAGE } from "../constant/ErrorMessages";
import { VideoTokenResponse } from "../interfaces/courses";
import { CustomError } from "../utils/CustomError";
import { apiFetch } from "./api";

export async function getSDKToken(): Promise<VideoTokenResponse> {
  try {
    return await apiFetch(`/online/token`, "POST");
  } catch (error) {
    throw new CustomError(DEFAULT_ERROR_MESSAGE);
  }
}
