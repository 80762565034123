import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

export interface Crumb {
  name: string;
  path?: string;
}

export const Breadcrumbs = ({ crumbs }: { crumbs: Crumb[] }) => {
  const prev: Crumb | null =
    crumbs.length > 1 ? crumbs[crumbs.length - 2] : null;
  return (
    <div className="flex mb-2">
      {prev && (
        <Link
          to={prev.path!}
          className="rounded-full p-2 hover:bg-gray-200 mr-2"
        >
          <ChevronLeftIcon className="h-8 w-8" />
        </Link>
      )}

      <nav className="invisible md:visible rounded-md w-full flex items-center p-2">
        <ol className="list-reset flex">
          {crumbs.map((value, key) =>
            !value.path || key + 1 === crumbs.length ? (
              <li
                className={"text-green-torcal " + key}
                key={`breadcrumb-${key}`}
              >
                {value.name}
              </li>
            ) : (
              <li key={`breadcrumb-${key}`}>
                <Link className="hover:text-green-torcal" to={value.path}>
                  {value.name}
                </Link>
                <span className="mx-2">/</span>
              </li>
            )
          )}
        </ol>
      </nav>
    </div>
  );
};
