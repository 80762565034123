import classNames from "classnames";

export default function LoadingBar({
  text = "loading",
  className,
}: {
  text?: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "flex flex-col sm:flex-row w-full justify-start bg-white shadow-lg p-6",
        className
      )}
    >
      <p className="text-md text-left text-gray-dark font-light">{text}</p>
    </div>
  );
}
