import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ProgressCard } from "../components/Card/ProgressCard";
import LoadingBar from "../components/Layout/LoadingBar";
import { ToolBar } from "../components/Layout/ToolBar";
import { useCourses } from "../hooks/useCourses";
import { useLessons } from "../hooks/useLessons";
import { Lesson } from "../interfaces/courses";
import { getLessonPercentage } from "../utils/Percentage";
import { getRoutePath } from "../utils/portalPages";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { useUser } from "../hooks/useUser";

export function Lessons() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<"all" | "incomplete">("all");
  const { isLoadingLessons, lessons } = useLessons();
  const { selectedCourse, isLoadingCourses, courses } = useCourses();
  const { setUserMetadata } = useUser();

  useEffect(() => {
    setUserMetadata({ currentPage: "Lecciones", currentLesson: null, currentTest: null });
  }, []);

  const changeFilter = (event: React.FormEvent<HTMLSelectElement>) => {
    // @ts-ignore
    setFilter(event.target.value);
  };

  const isLoadingContent = isLoadingLessons && (!lessons || lessons.length === 0);
  const isCourseNotAvailable = !isLoadingCourses && (!courses || courses.length === 0);

  const nextContent = useMemo(() => {
    return lessons.find((l) => getLessonPercentage(l) < 100);
  }, [lessons]);

  const filteredLessons = filter === "all" ? lessons.filter((l) => l.maxContent > 0) : lessons.filter((l) => l.maxContent > 0 && l.contentsDone < l.maxContent);

  const navigateToLesson = (lesson: Lesson) => {
    if (!selectedCourse) return;
    navigate(
      getRoutePath("lesson", {
        courseId: selectedCourse.id.toString(),
        lessonId: lesson.id.toString(),
      })
    );
  };

  if (isLoadingContent) {
    return <LoadingBar text={t("Lessons.loading-lessons")} />;
  }

  return (
    <div className="flex flex-col space-y-5">
      {selectedCourse && (
        <Breadcrumbs
          crumbs={[
            {
              name: "Inicio",
              path: getRoutePath("course", {
                courseId: selectedCourse?.id.toString()!,
              }),
            },
            { name: "Lecciones" },
          ]}
        />
      )}

      <ToolBar>
        <select className="w-full sm:w-56" onChange={changeFilter}>
          <option value="all">{t("Lessons.all-options")}</option>
          <option value="incomplete">{t("Lessons.incomplete-options")}</option>
        </select>
        {nextContent && selectedCourse ? (
          <Link
            to={getRoutePath("lesson", {
              courseId: selectedCourse.id.toString(),
              lessonId: nextContent.id.toString(),
            })}
            className="flex flex-col justify-center hover:text-orange-torcal"
          >
            <span className="text-xs text-gray-medium">{t("Lessons.next-lesson")}</span>
            <span className="text-sm">{nextContent.title}</span>
          </Link>
        ) : null}
      </ToolBar>

      <div className="bg-white flex flex-col justify-start p-6 md:py-10 md:px-14 shadow-lg">
        {isCourseNotAvailable ? (
          <p className="font-light mb-8">
            {t("Lessons.course-not-available")}
            <Link to={getRoutePath("courses")} className="text-orange-torcal block font-semibold">
              {t("Lessons.back-to-courses")}
            </Link>
          </p>
        ) : (
          <>
            {filteredLessons && filteredLessons.length === 0 ? (
              <p className="text-lg font-light mb-8">{t("Lessons.no-lessons-available")}</p>
            ) : (
              filteredLessons &&
              filteredLessons.length > 1 && (
                <div className="flex flex-col w-full">
                  <div className="grid grid-cols-1 justify-items-center sm:justify-items-start gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {selectedCourse &&
                      filteredLessons.map((lesson) => <ProgressCard key={lesson.id} item={lesson} onClick={navigateToLesson} progress={getLessonPercentage(lesson)} />)}
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}
