import { useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import { useRecoilValue } from "recoil";
import { meetingState } from "../../../state/meeting";
const { useParticipant } = require("@videosdk.live/react-sdk");

interface TeacherVideoProps {
  enableAudio: boolean;
  modeComplete: boolean;
}

export const TeacherView = ({ enableAudio, modeComplete }: TeacherVideoProps) => {
  const meetingStatus = useRecoilValue(meetingState);
  const micRef = useRef<any>(null);
  const { webcamStream, webcamOn, micStream, micOn, displayName, setQuality } = useParticipant(meetingStatus?.teacherId ?? "");

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      if (setQuality) setQuality("high");
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (enableAudio && micRef.current && micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      micRef.current.srcObject = mediaStream;
      micRef.current.play().catch((error: Error) => {
        if (error.message === "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD") {
          // eslint-disable-next-line no-console
          console.log("audio" + error.message);
        }
      });
    }
  }, [micStream, micOn, micRef, enableAudio]);

  // const widthPorcentage = modeComplete ? "70%" : "100%";
  const widthPorcentage = "100%"; // Always 100% for now

  return (
    <>
      {enableAudio && micOn && micRef && <audio autoPlay playsInline controls={false} ref={micRef} />}
      {webcamOn ? (
        <ReactPlayer
          pip={false}
          light={false}
          controls={false}
          muted={false}
          playing={true}
          url={videoStream}
          height={"100%"}
          width={widthPorcentage}
          style={{ objectFit: "cover" }}
          onError={(err: any) => {
            // TODO: send this to Sentry
            // eslint-disable-next-line no-console
            console.log(err, "participant video error");
          }}
        />
      ) : (
        <div className="bg-gray-900 flex flex-col flex-grow justify-center align-middle min-w-96 w-96">
          {displayName ? (
            <div className="relative text-xl text-gray-100 mx-auto rounded-3xl p-7 bg-orange-torcal">
              <p>{displayName}</p>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
