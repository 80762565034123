import { MicrophoneIcon, VideoCameraIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSDKToken } from "../../../api/video";
import { useClassroom } from "../../../hooks/useClassroom";
import { useCourses } from "../../../hooks/useCourses";
import { Meeting } from "../../../interfaces/meeting";
import { useUser } from "../../../hooks/useUser";

import { Button } from "../../Button";
import Spinner from "../../Layout/Spinner";
import { MeetingButton } from "../../Presentational/Meeting/MeetingButton";

export function JoinScreen({
  onJoinAction,
}: {
  onJoinAction: (meeting: Meeting) => void;
}) {
  const { t } = useTranslation();
  const videoRef: any = useRef();
  const [noMicDetected, setNoMicDetected] = useState<boolean>(false);
  const [noMicPermission, setNoMicPermission] = useState<boolean>(false);
  const [noWebCamDetected, setNoWebCamDetected] = useState<boolean>(false);
  const [noWebCamPermission, setNoWebCamPermission] = useState<boolean>(false);
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | undefined>();
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | undefined>();
  const [micEnabled, setMicEnabled] = useState<boolean>(true);
  const [isJoinMeetingInProgress, setIsJoinMeetingInProgress] =
    useState<boolean>(false);
  const [webcamEnabled, setWebcamEnabled] = useState<boolean>(true);
  const { selectedCourse } = useCourses();
  const { selectedLesson } = useClassroom();
  const { user, logout } = useUser();

  const initializeMedia = useCallback(async () => {
    if (videoRef && videoRef.current) {
      try {
        const videoDevice = await navigator.mediaDevices.getUserMedia({
          video: {
            width: 1280,
            height: 720,
          },
        });
        const videoTracks = videoDevice.getVideoTracks();
        videoRef.current.srcObject = videoDevice;
        setVideoTrack(videoTracks.length ? videoTracks[0] : undefined);
      } catch (error: any) {
        if (error?.message === "Requested device not found") {
          /** the user does not have a webcam */
          setNoWebCamDetected(true);
        } else {
          setNoWebCamPermission(true);
        }
      }

      try {
        const audioDevice = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioTracks = audioDevice.getAudioTracks();
        setAudioTrack(audioTracks.length ? audioTracks[0] : undefined);
        audioDevice.getAudioTracks()[0].stop();
      } catch (error: any) {
        if (error?.message === "Requested device not found") {
          /** the user does not have a microphone */
          setNoMicDetected(true);
        } else {
          setNoMicPermission(true);
        }
      }

      setMicEnabled(false);
    }
  }, [videoRef]);

  useEffect(() => {
    if (user?.username === undefined) {
      logout();
    }
    initializeMedia();
  }, []);

  useEffect(() => {
    return () => {
      videoTrack?.stop();
    };
  }, [videoTrack]);

  useEffect(() => {
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack]);

  const updateMicAction = () => {
    setMicEnabled(!micEnabled);
  };

  const joinMeetingAction = async () => {
    if (!selectedLesson?.sessionId) return;
    setIsJoinMeetingInProgress(true);
    let SDKTokenResponse = await getSDKToken();

    onJoinAction({
      meetingId: selectedLesson.meetingId,
      teacherId: selectedLesson.teacherId,
      micEnabled,
      webcamEnabled: true,
      SDKToken: SDKTokenResponse?.videosdkToken,
    });
  };

  return (
    <div className="w-1/3 py-12 mx-auto flex flex-col justify-start items-center">
      <p className="text-gray-torcal text-xl tracking-wide w-full">
        {selectedCourse?.title}
      </p>
      <p className="text-gray-torcal text-md font-light tracking-wide w-full mb-10">
        {selectedLesson?.title} {selectedLesson?.subTitle}
      </p>

      <p
        className={classNames("text-red-400 text-md text-center mb-6", {
          hidden: !noWebCamPermission && !noMicPermission,
        })}
      >
        {t("JoiningScreen.description")}
      </p>

      <Button
        type="button"
        className=""
        disabled={noWebCamPermission || noMicPermission}
        onClick={joinMeetingAction}
      >
        {isJoinMeetingInProgress && <Spinner />}
        <span>
          {t(
            isJoinMeetingInProgress
              ? "JoiningScreen.btn-loading"
              : "JoiningScreen.join"
          )}
        </span>
      </Button>

      <div className="relative w-full mx-auto my-10">
        <div
          className={classNames(
            "bg-gray-900 flex items-center justify-center h-96"
          )}
        >
          <video
            ref={videoRef}
            className={classNames("h-96", webcamEnabled ? "" : "hidden")}
            autoPlay
            muted
          />
          <p
            className={classNames(
              "text-white text-lg",
              webcamEnabled ? "hidden" : ""
            )}
          >
            Camera deshabilitada
          </p>
        </div>

        <div className="absolute w-full flex justify-center bottom-6 gap-4">
          <MeetingButton
            disabled={noMicDetected}
            onClick={updateMicAction}
            className={classNames(micEnabled ? "bg-gray-50" : "bg-red-400", {
              "opacity-25": noMicDetected,
            })}
          >
            <MicrophoneIcon
              className={classNames(
                "h-6",
                micEnabled ? "text-black" : "text-white"
              )}
            />
          </MeetingButton>
        </div>
      </div>
    </div>
  );
}
