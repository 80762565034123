import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

export default function Modal({
  isOpen,
  title,
  closeModal,
  children,
  showCloseIcon = true,
  size = "max-w-md",
}: {
  isOpen: boolean;
  showCloseIcon?: boolean;
  title?: string;
  size?: "max-w-5xl" | "max-w-md";
  closeModal: () => void;
  children: ReactNode;
}) {
  return (
    <Dialog
      as="div"
      className="relative z-50"
      onClose={closeModal}
      open={!!isOpen}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 bg-black bg-opacity-25" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            className={`w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${size}`}
          >
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900 flex"
            >
              <p className="grow">{title}</p>
              {showCloseIcon ? (
                <button onClick={closeModal}>
                  <XMarkIcon className="h-5 fill-black" />
                </button>
              ) : null}
            </Dialog.Title>
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
