import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getLessons as getLessonsAPI } from "../api/lessons";
import { lessonsState } from "../state/global";

export function useLessons() {
  const { courseId } = useParams();
  const [error, setError] = useState<string | undefined>();
  const [isLoadingLessons, setIsLoadingLessons] = useState<boolean>(true);
  const [lessons, setLessons] = useRecoilState(lessonsState);

  const getLessons = useCallback(async (): Promise<void> => {
    if (!courseId) return;
    setIsLoadingLessons(true);
    setLessons([]);
    setError(undefined);
    try {
      setLessons(await getLessonsAPI(courseId));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoadingLessons(false);
    }
  }, [courseId]);

  useEffect(() => {
    getLessons();
  }, [courseId]);

  return {
    isLoadingLessons,
    error,
    lessons,
  };
}
