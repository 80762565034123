import classNames from "classnames";
import { Lesson } from "../../interfaces/courses";
import { TestBySubject } from "../../interfaces/tests";

export function ProgressCard<Type extends TestBySubject | Lesson>({
  item,
  onClick,
  progress,
}: {
  item: Type;
  onClick: (item: Type) => void;
  progress?: number;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={() => onClick(item)}
      className={classNames(
        "p-4 max-h-fit text-left rounded-md flex flex-col justify-start w-72 border border-gray-light bg-gray-100 shadow-lg group hover:border-orange-600 hover:shadow-neutral-400"
      )}
    >
      {item.fotoPublicUrl ? (
        <img
          className="h-24 mx-auto mb-6"
          src={item.fotoPublicUrl}
          alt={item.title}
        />
      ) : null}

      <p className="text-sm break-words mb-6">{item.title}</p>
      {progress !== undefined ? (
        <div className="w-full text-center mt-auto">
          <div className="overflow-hidden h-5 text-xs flex bg-gray-200 rounded-full w-full">
            <div
              style={{
                width: `${progress}%`,
              }}
              className={classNames(
                "shadow-none flex flex-col text-center whitespace-nowrap justify-center",
                progress === 100 ? "bg-green-torcal/70" : "bg-orange-torcal/70"
              )}
            >
              {progress}%
            </div>
          </div>
        </div>
      ) : null}
    </button>
  );
}
