import { Disclosure } from "@headlessui/react";
import { CheckCircleIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useLesson } from "../../hooks/useLesson";
import {
  LessonContentContent,
  LessonContentSubmenu,
} from "../../interfaces/courses";

const getContentText = (
  content: LessonContentContent,
  index: number
): string => {
  if (content.question?.length > 0) return content.question;
  if (content.explain?.length > 0) return content.explain;

  if (content.type === "video") {
    return `Vídeo ${index + 1}`;
  } else {
    return `Contenido ${index + 1}`;
  }
};

const getContentsDone = (submenuItems: LessonContentContent[]): string => {
  const contentsDone = submenuItems.reduce(
    (previousValue, currentValue) =>
      previousValue + (currentValue.viewed ? 1 : 0),
    0
  );

  return `${contentsDone}/${submenuItems.length}`;
};

const getSubmenuItems = (
  submenu: LessonContentSubmenu,
  contents: LessonContentContent[]
): LessonContentContent[] => {
  return contents.filter((c) => c.submenuId === submenu.id);
};

export function SubmenuBar() {
  const { selectedContent, lessonContent, setSelectedContent } = useLesson();

  if (!lessonContent) return null;

  return (
    <>
      {lessonContent?.submenu.map((submenu) => (
        <Disclosure
          key={submenu.id}
          defaultOpen={submenu.id === selectedContent?.submenuId}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  "flex text-left p-4 space-y-3 justify-end border-b border-b-gray-medium hover:bg-gray-200",
                  open ? "bg-gray-200" : "bg-white"
                )}
              >
                <div className="grow flex flex-col">
                  <span className="text-gray-torcal">
                    {getContentsDone(
                      getSubmenuItems(submenu, lessonContent.content)
                    )}
                  </span>
                  <span className="text-lg">{submenu.title}</span>
                </div>
                <ChevronUpIcon
                  className={classNames(
                    "shrink-0 w-6 h-6",
                    !open ? "rotate-180 transform" : null
                  )}
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                {getSubmenuItems(submenu, lessonContent.content).map(
                  (content, index) => (
                    <button
                      key={content.id}
                      className={classNames(
                        "w-full bg-gray-50 flex align-middle p-4 space-y-3 justify-end border-r-[10px] border-b-gray-medium hover:bg-gray-200",
                        content.id === selectedContent?.id
                          ? "border-green-torcal"
                          : "border-transparent"
                      )}
                      onClick={() => setSelectedContent(content)}
                    >
                      <p className="flex grow flex-col justify-start text-sm">
                        <span className="text-left truncate w-[300px]">
                          {getContentText(content, index)}
                        </span>
                      </p>
                      <CheckCircleIcon
                        className={classNames(
                          "shrink-0 h-8 w-8",
                          content.viewed ? "fill-green-torcal" : "fill-gray-400"
                        )}
                      />
                    </button>
                  )
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
}
