import { onValue } from "firebase/database";
import { useEffect, useState } from "react";
import { getReference } from "../utils/firebase";
import { getPreffixFirebase } from "../api/api";

export function useMaintenance() {
  const [isInMaintenance, setIsInMaintenance] = useState<boolean>(false);

  useEffect(() => {
    const reference = getReference(`serverReboot${getPreffixFirebase()}`);

    return onValue(reference, (snapshot: any) => {
      const data = snapshot.val();
      setIsInMaintenance(data);
    });
  }, []);

  return {
    isInMaintenance,
  };
}
