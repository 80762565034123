import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

export default function ChatIcon({
  showChat,
  onOpenChat,
}: {
  showChat: boolean;
  onOpenChat: () => void;
}) {
  return (
    <button
      onClick={onOpenChat}
      className={classNames(
        showChat ? "hidden" : "fixed z-50 bottom-10 right-16"
      )}
    >
      <ChatBubbleLeftRightIcon className="h-16 w-16 fill-orange-torcal hover:fill-orange-700" />
    </button>
  );
}
