import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart } from "recharts";
import { TestInfo } from "../../interfaces/tests";

const data = [
  { name: "Group A", value: 40 },
  { name: "Group B", value: 60 },
];

export function CourseInfoCard({
  title,
  disabled = false,
  subTitle,
  imgSrc,
  info,
  progress,
}: {
  title: string;
  disabled?: boolean;
  subTitle: string;
  imgSrc: string;
  info?: TestInfo;
  progress?: number;
}) {
  const { t } = useTranslation();
  return (
    <>
      <img
        className="h-56 w-56 bg-contain bg-center rounded-lg"
        src={imgSrc}
        alt={title}
      />
      <p className="my-3 text-xl text-center">{title}</p>
      <div
        className={classNames(
          "text-white text-center text-sm w-full rounded-md mb-4 py-2",
          disabled
            ? " bg-gray-400 group-hover:bg-gray-500 group-hover:cursor-not-allowed"
            : " bg-green-torcal group-hover:bg-green-700"
        )}
      >
        {subTitle}
      </div>
      {info ? (
        <>
          {progress !== undefined ? (
            <div className="relative mb-4">
              <PieChart width={215} height={100}>
                <Pie
                  data={[
                    { name: "done", value: progress },
                    { name: "notDoneYet", value: 100 - progress },
                  ]}
                  cx={"46%"}
                  cy={100}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={70}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        index === 0
                          ? progress === 100
                            ? "#13A538"
                            : "#F07E15"
                          : "#fff"
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
              <span className="w-full text-center text-4xl font-bold absolute bottom-0">
                {progress}%
              </span>
            </div>
          ) : null}

          <div
            className={classNames(
              "px-6 flex justify-around w-full rounded-md mb-4 py-1",
              info.mediaErrors === 0
                ? "bg-green-200 text-green-torcal "
                : "bg-red-200 text-red-700 "
            )}
          >
            <span className="text-xs flex items-center">
              {t("Course.error-average")}
            </span>
            <span className="text-2xl">{info.mediaErrors}</span>
          </div>
        </>
      ) : null}
    </>
  );
}
