import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";

export function ControlButtons({
  onHaveDoubt,
  updateValue,
  currentValue,
  maxValue,
  isReview = false,
  haveDoubt = false,
  onFinishTest,
}: {
  isReview?: boolean;
  haveDoubt?: boolean;
  updateValue: (value: number) => void;
  onHaveDoubt: () => void;
  currentValue: number;
  maxValue: number;
  onFinishTest: () => void;
}) {
  const { t } = useTranslation();

  const handleKeyPress = (event: KeyboardEvent) => {
    const { key } = event;
    if (key === "ArrowLeft" && currentValue - 1 >= 0)
      updateValue(currentValue - 1);
    if (key === "ArrowRight" && currentValue + 1 < maxValue)
      updateValue(currentValue + 1);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentValue]);

  return (
    <div className="flex justify-start w-full">
      <div className="grow">
        {isReview ? null : (
          <div className="flex gap-5">
            <Button
              onClick={() => {
                updateValue(currentValue - 1);
              }}
              disabled={currentValue - 1 < 0}
              className="border-green-torcal hover:bg-green-700/20 hover:text-gray-700 disabled:bg-white disabled:text-gray-700/50 disabled:border-gray-700/50 disabled:ring-gray-700/50 focus:ring-green-torcal"
            >
              {t("Test.back")}
            </Button>
            <Button
              onClick={() => {
                updateValue(currentValue + 1);
              }}
              disabled={currentValue + 1 >= maxValue}
              className="border-green-torcal hover:bg-green-700/20 hover:text-gray-700 disabled:bg-white disabled:text-gray-700/50 disabled:border-gray-700/50 disabled::ring-green-700/50 focus:ring-green-torcal"
            >
              {t("Test.next")}
            </Button>
          </div>
        )}
      </div>
      {isReview ? null : (
        <div className="flex gap-5">
          <Button
            className="group relative mr-6"
            color="orange"
            onClick={onHaveDoubt}
          >
            <div className="absolute -top-[50px] items-center hidden mb-6 group-hover:flex flex-grow">
              <span className="w-72 relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                Al finalizar el test y ver la revisión te mostraremos la
                respuesta a esta pregunta.
              </span>
              <div className="absolute left-1/2 top-8 w-3 h-3 -mr-10 rotate-45 bg-black"></div>
            </div>
            <QuestionMarkCircleIcon className="w-6 h-6 mr-2 -ml-2" />
            {t("Test.haveDoubt")}
            {haveDoubt ? <CheckCircleIcon className="ml-2 h-6 -mr-3" /> : null}
          </Button>
          <Button
            onClick={onFinishTest}
            className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
          >
            {t("Test.finalize")}
          </Button>
        </div>
      )}
    </div>
  );
}
