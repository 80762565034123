import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function ZoomButtons() {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState<number>(100);

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = `${zoom}%`;
  }, [zoom]);

  return (
    <div className="flex justify-center space-x-3">
      <button
        onClick={() => {
          setZoom(zoom - 5);
        }}
      >
        <MagnifyingGlassMinusIcon className="h-8 w-8" />
      </button>
      <span>{t("Test.zoom-view")}</span>
      <button
        onClick={() => {
          setZoom(zoom + 5);
        }}
      >
        <MagnifyingGlassPlusIcon className="h-8 w-8" />
      </button>
    </div>
  );
}
