import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

/**
 * @description This component renders a video
 */
export const ContentVideo = ({
  videoUrl,
  status,
  seek,
  showControls = false,
}: {
  videoUrl: string;
  status: "play" | "pause";
  seek?: number;
  showControls?: boolean;
}) => {
  const playerRef = useRef<any>();

  useEffect(() => {
    if (seek && playerRef.current) {
      playerRef.current.seekTo(seek, "seconds");
    }
  }, [seek, status]);

  return (
    <ReactPlayer
      ref={playerRef}
      url={videoUrl}
      playing={status === "play"}
      controls={showControls}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
      }}
      style={{ margin: "auto" }}
    />
  );
};
